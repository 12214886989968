import img404 from "./../../img/404.jpg"

const Body = () => {
    return (
        <div className="hk-pg-body">
            <div className="row">
                <div className="d-lg-block d-none col-xl-8 col-lg-6">
                    <div className="auth-content py-md-0 py-8">
                        <div className="row">
                            <div className="text-center col-xl-12">
                                <img src={img404} className="img-fluid w-sm-80 w-50" alt="login"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-7 col-sm-10 text-center">
                    <div className="auth-content py-8">
                        <div className="w-100">
                            <div className="row">
                                <div className="col-xxl-9 col-xl-8 col-lg-11">
                                    <p className="p-lg">Вибачте, потрібну сторінку не знайдено. Спробуйте перейти на
                                        головну сторінку.</p>
                                    <button tabIndex="0"
                                       onClick={() => {window.location.href = '/'}}
                                       className="mt-4 btn btn-primary">На головну</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Page404 = () => {
    return (
        <div className="container-xxl">
            <Body/>
        </div>
    )
}

export default Page404