import BrandNav from "../../Authentication/BrandNav";
import AuthBody from "../../Authentication/AuthBody";
import Footer from "../../Footer/Footer";

const ResetPasswordContent = () => {
    return (
        <div className="auth-content py-8">
            <form className="w-100">
                <div className="row">
                    <div className="mx-auto col-lg-10 col-md-10 col-sm-10">
                        <div className="card-flush card">
                            <div className="text-center card-body">
                                <h4>Відновити пароль</h4>
                                <p className="mb-4">Скидання забутого пароля облікового запису кабінету електронних послуг
                                </p>
                                <div className="gx-3 row">
                                    <div className="mb-3 col-lg-12">
                                        <div className="form-label-group">
                                            <label className="form-label" htmlFor="userName">Адреса електронної пошти</label>
                                            {/*<a className="fs-7 fw-medium" href="/auth/reset-password">Вхід</a>*/}
                                        </div>
                                        <input placeholder="user@example.com" type="email" className="form-control"/>
                                    </div>
                                </div>
                                <button type="button" className="btn-uppercase btn-block btn btn-primary">Надіслати
                                </button>
                                <p className="p-xs mt-2 text-center">
                                <a className="text-dark-60" href="/signup">Новий користувач?&nbsp;&nbsp;
                                    <u className="text-primary">Створити обліковий запис</u>
                                </a>
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

const ResetPassword = () => {
    return (
        <div className={"hk-wrapper hk-pg-auth"} data-footer={"simple"}>
            <nav className="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
                <BrandNav/>
            </nav>

            <div className={"hk-pg-wrapper"}>
                <AuthBody>
                    <ResetPasswordContent/>
                </AuthBody>
                <Footer/>
            </div>

        </div>
    )
}

export default ResetPassword