import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import { store } from './store'
import { Provider } from 'react-redux'

import {BrowserRouter} from "react-router-dom";

import {ReactNotifications} from 'react-notifications-component';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <ReactNotifications/>
            <App/>
        </Provider>
    </BrowserRouter>
);
