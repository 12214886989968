import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    shipment_by_days: [],
    next_order: {
        uid: "",
        number: "",
        delivery_date: "",
        delivery_time: "",
        delivery_address: "",
        manager: {
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
        },
        project_manager: {
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
        },
    },
    last_news: []
}

export const Dashboard = createSlice({
    name: 'Dashboard',
    initialState,
    reducers: {
        setShipmentByDays: (state, action) => {
            return {...state, shipment_by_days: action.payload}
        },
        setNextOrder: (state, action) => {
            return {...state, next_order: action.payload}
        },
        setLastNews: (state, action) => {
            return {...state, last_news: action.payload}
        },
    },
})

export const {
    setShipmentByDays,
    setLastNews,
    setNextOrder
} = Dashboard.actions
export default Dashboard.reducer