function emptyOrValue(value, fixed = 2) {
    return value > 0 ? value.toFixed(fixed) : "";
}

function emptyOrZero(value, fixed = 2) {

    fixed = 0 ? fixed < 0 : fixed

    let zeroString = "0";

    if (fixed > 0) {
        zeroString = "0." + Array(fixed).fill(0).join('')
    }

    return value > 0 ? value.toFixed(fixed) : zeroString;
}

function emptyDate(value) {
    return value.length > 0 ? value : "00010101";
}

function emptyOderNumber(value) {
    return value.length > 0 ? value : "0";
}

module.exports = {
    emptyOrValue,
    emptyOrZero,
    emptyDate,
    emptyOderNumber
}