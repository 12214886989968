import {setErrors} from "../Reducers/Errors";
import {handleError} from "../Utils/Errors";

function repositoryResponse(status, responseData, dispatch, setData) {

    switch (status) {
        case 200: {
            switch (responseData.status) {
                case "SUCCESS":
                    dispatch(setData(responseData.data));
                    break;
                default:
                    dispatch(setErrors(['undefined error']))
            }
            break;
        }
        default: {
            window.location.href = '/';
        }
    }
}

function errorResponse(status, dispatch, error) {
    switch (status) {
        case 404: {
            window.location.href = '/404';
            break
        }
        default: {
            const errorList = handleError(error)
            dispatch(setErrors(errorList))
        }
    }
}

export {repositoryResponse, errorResponse}