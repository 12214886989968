import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    files: []
}

export const Dropzone = createSlice({
    name: 'Dropzone',
    initialState,
    reducers: {
        addFile: (state, action) => {
            return {...state, files: state.files.push(action.payload)}
        },
        clearFiles: (state, action) => {
            return {...state, files: []}
        },
    },
})

export const {
    addFile,
    clearFiles
} = Dropzone.actions
export default Dropzone.reducer