import {handleError} from "../Utils/Errors";


const UserRepository = (apiAdapter) => ({

    sendSupportMessage: async (subject, message) => {

        const data = {subject: subject, message: message}

        try {
            const response = await apiAdapter.post('/users/send-support-message/', data)
            return {data: response.data, status: response.status};
        } catch (error) {
            return {errors: handleError(error)}
        }

    },

});

export default UserRepository;