import {
    faUserLock,
    faUserCheck,
    faLock,
    faUnlock,
    faArrowsRotate,
    faCircleNodes,
    faTrashCan,
    faUserPlus,
    faSignal, faArrowLeft, faHouse
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useContext, useEffect, useMemo, useState} from "react";
import AuthContext from "../AuthContext";
import {useNavigate} from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";

import {setData, setTimeStamp, changeUserActivity} from "../../Reducers/Users";
import {setErrors} from "../../Reducers/Errors";
import {reset as resetFilteredData, setFilteredData} from "../../Reducers/FilteredData"

import {handleError, reportError} from "../../Utils/Errors";

import {useTable} from "react-table";
import UpdateUserUID from "./UpdateUserUID";
import {filterArrayOfObjects} from "../../Utils/arrayProcessing";

const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;
const locale = "uk_UA"

const UserListTableRow = ({rowData}) => {

    const dispatch = useDispatch()

    const [showUidWindow, setShowUidWindow] = useState(false)

    function handleUserActivity(username, is_active) {
        dispatch(changeUserActivity({username: username, is_active: !is_active}))
    }

    return (
        <tr {...rowData.getRowProps()}>
            <td className="">
                {rowData.original.user_has_uid === 1 ?
                    <span className="icon">
                        <span className="feather-icon">
                            <FontAwesomeIcon icon={faUserCheck} className="bi text-success me-1"/>
                        </span>
                    </span> : <></>
                }

                {rowData.original.is_active === false ?
                    <span className="icon">
                        <span className="feather-icon">
                            <FontAwesomeIcon icon={faUserLock} className="bi text-danger me-1"/>
                        </span>
                    </span> : <></>
                }
            </td>
            <td className="">
                <div className="media align-items-center">
                    <span className="icon">
                        <span className="feather-icon">
                            <FontAwesomeIcon icon={faSignal} className="bi text-success me-1"/>
                        </span>
                    </span>

                    <div className="media-body"><span
                        className="d-block text-high-em">{rowData.original.username}</span></div>
                </div>
            </td>
            <td className=""><span className="text-truncate d-block">{rowData.original.email}</span></td>
            <td className=""><span className="text-truncate d-block">
                <div className="media align-items-start">
                    {rowData.original.last_name}
                </div>
                <div className="media align-items-start">
                    {rowData.original.first_name}
                </div>
            </span></td>
            {/*<td className=""><span className="text-truncate d-block">{rowData.original.last_name}</span></td>*/}
            <td className=""><span className="text-truncate d-block">{rowData.original.company_name}</span></td>
            <td className=""><span className="text-truncate d-block">{rowData.original.phone_number}</span></td>

            <td className="">
                <div className="d-flex align-items-center">
                    <div className="d-flex">

                        <button type="button" tabIndex="0"
                            // data-bs-toggle="tooltip" data-placement="top"
                            // data-bs-original-title="Archive"
                                className="btn-icon btn-rounded flush-soft-hover btn btn-flush-dark"
                                onClick={() => {
                                    setShowUidWindow(true)
                                }}>
                            <span className="icon">
                                <span className="feather-icon">
                                    <FontAwesomeIcon icon={faCircleNodes} className="bi text-primary"/>
                                </span>
                            </span>
                        </button>

                        <UpdateUserUID show={showUidWindow} showHandler={setShowUidWindow}
                                       userName={rowData.original.username}
                                       companyName={rowData.original.company_name}/>

                        <button type="button"
                            // data-item={rowData.username}
                            // tabIndex="0"
                            // data-bs-toggle="tooltip" data-placement="top"
                            // data-bs-original-title="Archive"
                                className="btn-icon btn-rounded flush-soft-hover btn btn-flush-dark"
                                onClick={() => handleUserActivity(rowData.original.username, rowData.original.is_active)}
                        >
                            <span className="icon">
                                <span className="feather-icon">
                                    {rowData.original.is_active === true ?
                                        <FontAwesomeIcon icon={faLock} className="bi text-primary"/> :
                                        <FontAwesomeIcon icon={faUnlock} className="bi text-cyan"/>
                                    }
                                </span>
                            </span>
                        </button>

                        <button type="button" tabIndex="0"
                                data-bs-toggle="tooltip" data-placement="top"
                                data-bs-original-title="Archive"
                                className="btn-icon btn-rounded flush-soft-hover btn btn-flush-dark">
                            <span className="icon">
                                <span className="feather-icon">
                                    <FontAwesomeIcon icon={faTrashCan} className="bi text-warning"/>
                                </span>
                            </span>
                        </button>

                    </div>
                </div>
            </td>

        </tr>
    )
}

const UserListTable = () => {

    const data = useSelector(state => state.Pagination.currentItems)

    const columns = useMemo(
        () => [
            {Header: 'user_name', accessor: 'username'},
            {Header: 'Email', accessor: 'email'},
            {Header: 'First_name', accessor: 'first_name'},
            {Header: 'Last_name', accessor: 'last_name'},
            {Header: 'Company_name', accessor: 'company_name'},
            {Header: 'Phone_number', accessor: 'phone_number'}
        ], [])

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({columns, data});
    // setFilter('email','test')

    return (
        <table className="hk-data-table nowrap w-100 mb-5 table" {...getTableProps()}>
            <thead>
            <tr>
                <th className=""><span className="flex-grow-1">#</span></th>
                <th className=""><span className="flex-grow-1">Логін</span></th>
                <th className=""><span className="flex-grow-1">Email адреса</span></th>
                <th className=""><span className="flex-grow-1">Прізвище / Імʼя</span></th>
                {/*<th className=""><span className="flex-grow-1">Прізвище</span></th>*/}
                <th className=""><span className="flex-grow-1">Назва компанії</span></th>
                <th className=""><span className="flex-grow-1">Номер телефону</span></th>
                <th className=""><span className="flex-grow-1"></span></th>
            </tr>
            </thead>
            <tbody>
            {
                rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <UserListTableRow key={row.id} rowData={row}/>
                    )
                })
            }
            </tbody>
        </table>
    )
}

const UserListControlPanel = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (
        <div className="d-xxl-flex d-none align-items-center mb-0">

            <button type="button" className="btn btn-outline-light btn-sm me-2"
                    onClick={() => {navigate(-1)}}>
                <span>
                    <span className="icon">
                        <span className="feather-icon">
                            <FontAwesomeIcon icon={faArrowLeft} className="text-primary my-1"/>
                        </span>
                    </span>
                </span>
            </button>

            <button type="button" className="btn btn-outline-light btn-sm me-2"
                    onClick={() => {navigate('/')}}>
                <span>
                    <span className="icon">
                        <span className="feather-icon">
                            <FontAwesomeIcon icon={faHouse} className="text-primary my-1"/>
                        </span>
                    </span>
                </span>
            </button>

            {/*<div className="v-separator h-25p"></div>*/}

            <button type="button" className="btn btn-outline-light btn-sm me-2"
                    onClick={() => {
                    }}>
                <span>
                    <span className="icon">
                        <span className="feather-icon">
                            <FontAwesomeIcon icon={faUserPlus} className="text-primary"/>
                        </span>
                    </span>
                    <span className="btn-text">Створити</span>
                </span>
            </button>

            <button type="button" className="btn btn-outline-light btn-sm me-5"
                    onClick={() => {
                        dispatch(setTimeStamp())
                    }}>
                <span>
                    <span className="icon">
                        <span className="feather-icon">
                            <FontAwesomeIcon icon={faArrowsRotate} className="text-primary"/>
                        </span>
                    </span>
                    <span className="btn-text">Оновити</span>
                </span>
            </button>

            <label className="flex-shrink-0 mb-0 me-2">Фільтри:</label>

            <select className="w-130p form-select form-select-sm" defaultValue={"all"}>
                <option value="all">Всі</option>
                <option value="active">Активні</option>
                <option value="connected">Підключені</option>
                <option value="notconnected">Незареєстровані</option>
                <option value="blocked">Заблоковані</option>
            </select>

        </div>
    )
}

const UserList = () => {

    const dispatch = useDispatch()

    const fData = useSelector(state => state.FilteredData)
    const data = useSelector(state => state.Users.data)

    function searchHandler(e) {
        const filteredData = filterArrayOfObjects(data, e.target.value)
        dispatch(setFilteredData({data: filteredData, searchValue: e.target.value}))
    }

    return (
        <div className="contact-body">
            <div className="contact-list-view mt-3">
                <div className="mb-3 row">
                    <div className="col-9">
                        <div className="contact-toolbar-left">
                            <UserListControlPanel/>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="contact-toolbar-right">
                            <div className="dataTables_filter d-flex justify-content-end">
                                <label className="form-label">
                                    <input placeholder="Search" type="search"
                                           className="form-control form-control-sm"
                                           defaultValue=""
                                           onInput={(e) => {
                                               searchHandler(e)
                                           }}/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Table*/}
                <div className="table-responsive">
                    <UserListTable/>
                </div>
                {/*...Table*/}
                {/*Pagination*/}
                <div className="mb-3 row">
                    <div
                        className="d-flex justify-content-end">
                        <Pagination itemsPerPage={10} data={(fData.is_filtered) ? fData.data : data}/>
                    </div>
                </div>
                {/*...Pagination*/}
            </div>
        </div>
    )
}

const AdminUsers = () => {

    const dispatch = useDispatch()

    let {isAuthenticated, isAdmin} = useContext(AuthContext)
    const timeStamp = useSelector(state => state.Users.timeStamp)

    const navigate = useNavigate()

    useEffect(() => {

        dispatch(resetFilteredData())

        if (isAuthenticated !== true || isAdmin !== true) {
            navigate("/")
        } else {
            axios.get(`${baseUrl}/users/users-list/`,
                {
                    timeout: 5000,
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    }
                }).then(function (response) {

                if (response.status === 200) {

                    const responseData = response.data;
                    switch (responseData.status) {
                        case "SUCCESS":
                            dispatch(setData(responseData.data))
                            break;
                        default:
                            dispatch(setErrors([reportError('OperationErrorTryLater',locale)]))
                    }
                }

            }).catch(function (error) {
                const errorList = handleError(error)
                dispatch(setErrors(errorList))
            })
        }
    }, [isAuthenticated, timeStamp]);

    return (
        <div className="container-xxl">
            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">Користувачі</h1>
            </div>
            <div className="hk-pg-body">
                <div className="contactapp-content">
                    <div className="contactapp-detail-wrap">
                        {/*User list*/}
                        <UserList/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminUsers