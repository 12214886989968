import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: []
}

export const Errors = createSlice({
    name: 'Errors',
    initialState,
    reducers: {
        setErrors: (state, action) => {
            return {...state, data: action.payload}
        }
    },
})

export const {
    setErrors} = Errors.actions
export default Errors.reducer