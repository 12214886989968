import Nav from "react-bootstrap/Nav";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import {useDispatch, useSelector} from "react-redux";
import {useContext} from "react";
import AuthContext from "../AuthContext";
import axios from "axios";
import Cookies from "js-cookie";

import {setData} from "../../Reducers/UserProfile";
import {setErrors} from "../../Reducers/Errors"

import ErrorNotification from "../Notifications/ErrorNotification";


const TabContentProfileData = () => {

    const user_info = useSelector(state => state.User.data.profile)

    return (
        <div className="tab-pane" id="tab_profile_data">
            <form>
                <div className="row gx-3">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <div className="media align-items-center">
                                <div className="media-head me-5">
                                    <div
                                        className="avatar avatar-primary avatar-xxl avatar-rounded">
                                        <span className="initial-wrap">{user_info.short_name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="title title-xs title-wth-divider text-primary text-uppercase my-4">
                    <span>Особиста інформація</span></div>
                <div className="row gx-3">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Ім’я</label>
                            <input name="first_name" className="form-control" type="text"
                                   defaultValue={user_info.first_name}/>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Прізвище</label>
                            <input name="last_name" className="form-control" type="text"
                            defaultValue={user_info.last_name}/>
                        </div>
                    </div>
                </div>
                <div className="row gx-3">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label className="form-label">Email адреса</label>
                            <input name="email" className="form-control" type="text"
                            defaultValue={user_info.email}/>
                        </div>
                    </div>
                </div>
                <div
                    className="title title-xs title-wth-divider text-primary text-uppercase my-4">
                    <span>Додаткова інформація</span></div>
                <div className="row gx-3">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label className="form-label">Назва компанії</label>
                            <input className="form-control" name="company_name" type="text"
                            defaultValue={user_info.company_name}/>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label className="form-label">Телефон</label>
                            <input className="form-control" name="phone_number" type="text"
                            defaultValue={user_info.phone_number}/>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-primary mt-5">Зберегти</button>
            </form>
        </div>
    )
}

const TabContentAdditionalParameters = () => {
    return (
        <div className="tab-pane" id="tab_additional_parameters">
            <div className="title-lg fs-4 mb-4"><span>Додаткові параметри</span></div>
            <form>
                <div className="row gx-3">
                    <div className="col-sm-12">
                        <div className="form-check form-check-lg">
                            <input type="checkbox" className="form-check-input"
                                   id="customChecks1"/>
                            <label className="form-check-label mt-0"
                                   htmlFor="customChecks1">let others find me by email
                                address</label>
                            <small className="form-text text-muted d-block">People who
                                have your email address will be able to connect you by
                                Jampack</small>
                        </div>
                        <div className="separator"></div>
                        <div className="form-check form-check-lg">
                            <input type="checkbox" className="form-check-input"
                                   id="customChecks2"/>
                            <label className="form-check-label mt-0"
                                   htmlFor="customChecks2">Keep my phone number
                                private</label>
                            <small className="form-text text-muted d-block">No one can
                                find you by your phone number. Your phone number will
                                not be shared with your contact anymore.</small>
                        </div>
                        <div className="separator"></div>
                        <div className="form-check form-check-lg">
                            <input type="checkbox" className="form-check-input"
                                   id="customChecks3"/>
                            <label className="form-check-label mt-0"
                                   htmlFor="customChecks3">All Keep my location sharing
                                on</label>
                            <small className="form-text text-muted d-block">Jmapack
                                webapp shares your location wherever you go</small>
                        </div>
                        <div className="separator"></div>
                        <div className="form-check form-check-lg">
                            <input type="checkbox" className="form-check-input"
                                   id="customChecks4"/>
                            <label className="form-check-label mt-0"
                                   htmlFor="customChecks4">Share data through select
                                partnerships</label>
                            <small className="form-text text-muted d-block">Lorem ipsum
                                dolor sit amet, consectetur adipiscing elit.
                                Pellentesque condimentum mauris volutpat enim ornare
                                iaculis. Curabitur euismod rutrum lorem id lobortis.
                                Cras ut ex dui. Nulla sed blandit tortor. In quam diam,
                                efficitur sit amet pulvinar eget, consequat placerat
                                arcu.</small>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-primary mt-5">Зберегти зміни
                </button>
            </form>
        </div>
    )
}

const TabContentLoginSecurity = () => {
    return (
        <div className="tab-pane" id="tab_login_security">
            <div className="title-lg fs-4"><span>Вхід і безпека</span></div>
            <p className="mb-4">The Avatar component is used to represent a user, and
                displays the profile picture, initials or fallback icon.</p>

            <div
                className="title title-xs title-wth-divider text-primary text-uppercase my-4">
                <span>Параметри пароля</span></div>
            <div className="row gx-3">
                <div className="col-sm-12">
                    <div className="form-group">
                        <button type="button"

                                className="btn btn-primary mt-3">Змінити пароль
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const NavContentProfile = () => {
    return (
        <Tab.Container id="left-tabs" defaultActiveKey="tab_profile_data">
            <Row className="edit-profile-wrap">
                <Col className="col-lg-2 col-sm-3 col-4">
                    <div className="nav-profile mt-4">
                        <div className="nav-header">
                            <span>Обліковий запис</span>
                        </div>
                        <Nav className="nav-light nav-vertical nav-tabs" as="ul">
                            <Nav.Item as="li">
                                <Nav.Link eventKey="tab_profile_data">
                                    <span className="nav-link-text">Мій профіль</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tab_additional_parameters">
                                    <span className="nav-link-text">Додаткові параметри</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tab_login_security">
                                    <span className="nav-link-text">Вхід і безпека</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                </Col>
                <Col className="col-lg-10 col-sm-9 col-8">
                    <Tab.Content>
                        <Tab.Pane eventKey="tab_profile_data"><TabContentProfileData/></Tab.Pane>
                        <Tab.Pane eventKey="tab_additional_parameters"><TabContentAdditionalParameters/></Tab.Pane>
                        <Tab.Pane eventKey="tab_login_security"><TabContentLoginSecurity/></Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}

const Profile = () => {

    const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;
    const dispatch = useDispatch()

    let {isAuthenticated} = useContext(AuthContext)

    if (isAuthenticated === true) {
        axios.get(`${baseUrl}/users/profile/`,
            {
                timeout: 5000,
                withCredentials: true,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-CSRFToken": Cookies.get("csrftoken")
                }
            }).then(function (response) {

            if (response.status === 200) {

                const responseData = response.data;

                switch (responseData.status) {
                    case "SUCCESS":
                        dispatch(setData(responseData.data))
                        break;
                    case "ERROR":
                        dispatch(setErrors([responseData.error]))
                        break;
                    default:
                        dispatch(setErrors(['Undefined error. Bad server response.']))
                }
            }
        }).catch(function (error) {

            const responseData = error.response.data;

            switch (error.response.status) {
                case 400: {
                    dispatch(setErrors([responseData.error]))
                    break;
                }
                default: {
                    dispatch(setErrors(['Undefined error. Server error.']))
                }
            }
        })
    }

    return (
        <div className="container-xxl">

            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">Редагувати профіль</h1>
            </div>

            <div className="hk-pg-body">
                <NavContentProfile/>
            </div>
            {/*<ErrorsSection store={'User'}/>*/}
            <ErrorNotification position={'bottom-left'}/>
        </div>
    )
}

export default Profile