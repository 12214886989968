import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    messages: []
}

export const Notifications = createSlice({
    name: 'Notifications',
    initialState,
    reducers: {
        setNotifications: (state, action) => {
            return {...state, messages: action.payload}
        }
    },
})

export const {
    setNotifications} = Notifications.actions
export default Notifications.reducer