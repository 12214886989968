import {createSlice} from "@reduxjs/toolkit";
import moment from "moment/moment";

const initialState = {
    timeStamp: Date.now().toString(),
    period: {
        // startDate: moment().subtract(10, 'days').format("YYYYMMDD"),
        startDate: localStorage.getItem('DRPStartDate') ? localStorage.getItem('DRPStartDate') : moment().format("YYYYMMDD"),
        endDate: localStorage.getItem('DRPEndDate') ? localStorage.getItem('DRPEndDate') : moment().format("YYYYMMDD")
        // endDate: moment().format("YYYYMMDD"),
    },
    date: moment().format("YYYYMMDD"),
}

export const DateRangePicker = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setPeriod: (state, action) => {
            return {...state, period: action.payload, timeStamp: Date.now().toString()}
        },
        setTimeStamp: (state, action) => {
            return {...state, timeStamp: action.payload}
        },
        setDate: (state, action) => {
            return {...state, date: action.payload, timeStamp: Date.now().toString()}
        }
    },
})

export const {
    setPeriod,
    setTimeStamp,
    setDate
} = DateRangePicker.actions
export default DateRangePicker.reducer