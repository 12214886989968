import SVGLogo from "../SVGLogo/SVGLogo_new";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion} from "@fortawesome/free-regular-svg-icons";

const BrandNav = () => {
    return (
        <div className="container-xxl">
            {/*Start Nav*/}
            <div className="nav-start-wrap">
                <a className="navbar-brand" href="/">
                    {/*<img className="brand-img d-inline-block" src={logoVST} alt="brand"/>*/}
                    <span className="svg-rated" style={{height: "50px", width: "160px"}}><SVGLogo/></span>
                </a>

            </div>
            {/*/Start Nav*/}

            {/*End Nav*/}
            <div className="nav-end-wrap">
                <ul className="navbar-nav flex-row">
                    <li className="nav-item nav-link py-0">
                        <button className="btn btn-sm btn-outline-light">
                                <span>
                                    <span className="icon">
                                        <span className="feather-icon">
                                            <FontAwesomeIcon icon={faCircleQuestion} className="feather"/>
                                        </span>
                                    </span>
                                    <span>Отримати допомогу</span></span>
                        </button>
                    </li>
                </ul>
            </div>
            {/*/End Nav*/}
        </div>
    )
}

export default BrandNav