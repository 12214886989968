import {Store} from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Variants} from "../../Utils/Emuns/NotificationsVariants";

const NotificationTitle = ({title, dateTime}) => {
    return (
        <span>
            <FontAwesomeIcon icon={faInfoCircle} className="feather me-1 text-warning"/>{title}&nbsp;&nbsp;
            <span className="fs--2 text-yellow">{dateTime}</span>
        </span>
    )
}

const Notification = () => {

    const position='bottom-left'
    const variant = Variants.INFO; //primary, warning, danger, info, success
    const messages = useSelector(state => state.Notification.messages)
    const orderDataMessages = useSelector(state => state.OrderData.notifications)
    const userMessage = useSelector(state => state.Users.notifications)

    const allMessages = messages
        .concat(orderDataMessages)
        .concat(userMessage)

    useEffect(() => {
        allMessages.map(el =>
        Store.addNotification({
            title: <NotificationTitle title={'Інфо!'}/>,
            // message: el.message,
            message: el,
            type: variant,
            insert: "top",
            container: position,
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true,
                showIcon: true,
            }
        })
    )
    }, [allMessages]);

    return (<></>)
}

export default Notification;