import axios from 'axios';
import Cookies from "js-cookie";

const aTimeout = 5000;
const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;

const ApiAdapter = () => {
    const client = axios.create({
        baseURL: baseUrl
    });

    return {
        get: async (url) => {
            return await client.get(url,
                {
                    timeout: aTimeout,
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    }
                });
        },

        post: async (url, data) => {
            return await client.post(url,
                data,
                {
                    timeout: aTimeout,
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    }
                });
        },

        // put: async (url, data) => {
        //     const response = await client.put(url, data);
        //     return response.data;
        // },
        //
        // delete: async (url) => {
        //     const response = await client.delete(url);
        //     return response.data;
        // }
    };
};

export default ApiAdapter;