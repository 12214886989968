import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    timeStamp: Date.now().toString(),
    data: [],
    errors: [],
}

export const Blog = createSlice({
    name: 'Blog',
    initialState,
    reducers: {
        setData: (state, action) => {
            return {...state, data: action.payload}
        },
        setTimeStamp: (state, action) => {
            return {...state, timeStamp: Date.now().toString()}
        },
    },
})

export const {
    setData,
    setTimeStamp
} = Blog.actions
export default Blog.reducer