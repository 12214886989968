import {useDispatch} from "react-redux";
import {useForm, FormProvider} from "react-hook-form";
import ErrorMessage from "../Authentication/ErrorMessage";
import {sendSupportMessage} from "../../Reducers/Users";

const SupportForm = () => {

    const dispatch = useDispatch();

    const methods = useForm()
    const {register,
        clearErrors,
        handleSubmit,
        formState: {errors}} = methods

    const onSubmitHandler = (formValues) => {
        dispatch(sendSupportMessage(formValues));
        methods.setValue('subject', "")
        methods.setValue('message', "")
        clearErrors('subject')
        clearErrors('message')
    }

    return (
        <div className="card card-border mb-0 h-100">
            <div className="card-header card-header-action">
                <h6>
                    Тут ви можете задати будь-яке питання стосовно ваших замовлень, співпраці та підтримки. Ми з Вами
                    звʼяжемось для його вирішення протягом 1 робочого дня.
                </h6>
                <div className="card-action-wrap">
                    {/*  Free  */}
                </div>
            </div>
            <div className="card-body">
                <div className="contact-list-view">
                    <FormProvider {...methods}>
                        <form noValidate={true} className="w-100" onSubmit={handleSubmit(onSubmitHandler)}>
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <div className="form-label-group">
                                        <label htmlFor="username">Тема</label>
                                    </div>
                                    <input className={"form-control"}
                                           placeholder="Напишіть тему повідомлення"
                                           maxLength="150"
                                           autoComplete={"on"}
                                           id="subject"
                                           {...register("subject", {required: true})}
                                           type="text"/>
                                    <ErrorMessage field={"Тема"} type={errors["title"]?.type}/>
                                </div>
                                <div className="mb-3 col-md-12">
                                    <div className="form-label-group">
                                        <label className="form-label">Текст повідомлення</label>
                                        <small className="text-muted">1200</small>
                                    </div>
                                    <textarea rows="8"
                                              maxLength="1200"
                                              placeholder="Напишіть текст повідомлення"
                                              className="form-control"
                                              id="message"
                                              {...register("message", {required: true})}/>
                                    <ErrorMessage field={"Текст"} type={errors["message"]?.type}/>
                                </div>
                                <div className="row gx-3 justify-content-end">
                                    <button type="submit" className="btn btn-primary btn-uppercase btn-block w-40"
                                            name="submit">
                                        Відправити
                                    </button>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}

const Support = () => {
    return (
        <div className="container-xxl">
            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">Підтримка</h1>
            </div>
            <div className="hk-pg-body">
                <div className="row">
                    <div className="col-md-12 mb-md-4 mb-3">
                        <SupportForm/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Support