function updateArrayOfObjects(data, itemName, itemValue, itemFilterName, itemFilterValue) {

    let updateItem = data.find((item) => {
        return item[itemFilterName] === itemFilterValue
    })

    if (updateItem) {

        const newItem = {...updateItem}
        newItem[itemName] = itemValue

        const indexItem = data.findIndex(item => item[itemFilterName] === itemFilterValue)
        data.splice(indexItem, 1, newItem)
    }

    return data
}

function objectToString(item) {

    let itemString = '';

    if (typeof item == 'object') {
        Object.keys(item).forEach(key => {
            if (typeof item[key] == "object")
                itemString.concat(objectToString(item[key]));
            else {
                if (item[key])
                    itemString = itemString.concat(item[key], "|");
            }
        });
    }else {
        itemString.concat(item.value,"|");
    }
    return itemString
}

function filterArrayOfObjects(data, searchValue) {

    let results = [];
    let searchString = '';

    if (searchValue.length >= 3)
        data.forEach(item => {

            searchString = ''
            searchString = searchString.concat(objectToString(item))
            // console.log(searchString)
            if (searchString.toLowerCase().includes(searchValue.toLowerCase())) {
                results.push(item)
            }

        })
    return results
}

module.exports = {
    updateArrayOfObjects,
    filterArrayOfObjects
}