import {FormProvider, useForm} from "react-hook-form";
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShareFromSquare} from "@fortawesome/free-regular-svg-icons";
import {faBan, faMapLocationDot} from "@fortawesome/free-solid-svg-icons";

import {sendAddressUpdateRequest} from "../../../Reducers/OrderData";
import {useDispatch} from "react-redux";
import ErrorMessage from "../../Authentication/ErrorMessage";
import {PrivateRoute} from "../../PrivateRoute";

const AddressWindow = ({show, showHandler, uid, address}) => {

    const dispatch = useDispatch()

    const methods = useForm({
        defaultValues: {
            uid: uid,
            address: address
        }
    })
    const {
        clearErrors,
        register,
        handleSubmit,
        formState: {errors}
    } = methods

    const onSubmitHandler = (formValues) => {
        dispatch(sendAddressUpdateRequest(formValues))
        methods.setValue('address', "")
        clearErrors('address')
        showHandler(false);
    }

    const onCancelHandler = () => {
        methods.setValue('address', "")
        clearErrors('address')
    }

    return (
        <PrivateRoute>
            <Modal size="md" centered show={show} onHide={() => showHandler(false)}>
                <FormProvider {...methods}>
                    <form noValidate={true} onSubmit={handleSubmit(onSubmitHandler)}>
                        <div className="modal-content border-0">
                            <Modal.Header closeButton className="mx-2">
                                <Modal.Title>Вкажіть нову адресу доставки</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <label className="form-label">Нова адреса</label>
                                <input type="text" className="form-control" readOnly={false}
                                       id="address"
                                       name="address"
                                       defaultValue={""}
                                       {...register("address", {required: true})}/>
                                <ErrorMessage field={"Нова адреса"} type={errors["address"]?.type}/>

                                <div className="fs-7 mt-3">
                                    <FontAwesomeIcon icon={faMapLocationDot}
                                                     className="fs-0 me-2 text-primary"/>
                                    Адреса доставки
                                </div>
                                <div className="text-dark fw-medium">{address}</div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="btn py-2 btn-sm" type="submit">
                                    <FontAwesomeIcon icon={faShareFromSquare}
                                                     className="fs-0 me-2 text-yellow"/>
                                    Відправити
                                </Button>
                                <Button className="btn bg-warning py-2 btn-sm border-yellow"
                                        onClick={() => {
                                            onCancelHandler()
                                            showHandler(false)
                                        }}>
                                    <FontAwesomeIcon icon={faBan} className="fs-0 me-2 text-primary"/>
                                    Скасувати
                                </Button>
                            </Modal.Footer>
                        </div>
                    </form>
                </FormProvider>
            </Modal>
        </PrivateRoute>
    )
}

export default AddressWindow