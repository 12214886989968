import {createContext, useEffect, useState} from "react";
// import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {session} from "../Reducers/Auth2";

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({children}) => {

    const dispatch = useDispatch()

    const isAuthenticated = useSelector(state => state.Auth2.isAuthenticated)
    const isAdmin = useSelector(state => state.Auth2.isAdmin)

    // console.log(`AuthProvider. auth = ${isAuthenticated} admin = ${isAdmin}`)

    // let [loading, setLoading] = useState(true)

    let contextData = {
        isAuthenticated: isAuthenticated,
        isAdmin: isAdmin
    }

    // dispatch(session())

    // console.log('AuthProvider')
    useEffect(() => {
        // if (loading) {
            dispatch(session())
            // console.log('dispatch(session())')
        // }
        // setLoading(!isAuthenticated)

        // let container = document.querySelector('[data-layout]');
        // container.classList.remove('container');
        // if (isAuthenticated === true)
        //     container.classList.add('container');
        // else
        //     container.classList.add('container-fluid');

    }, []);

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}