import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "axios";
import {reportError} from "../Utils/Errors";
import {AuthRepository, signUpInFulfilled} from "../Repositories/AuthRepository";
import ApiAdapter from "../Adapters/ApiAdapter";

const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;

const initialState = {

    // isAuthenticated: localStorage.getItem('isAuthenticated') === true ? true : false,
    isAuthenticated: localStorage.getItem('isAuthenticated'),
    // isAuthenticated: !!Cookies.get("sessionid"),
    isAdmin: false,
    // isSignUp: false,
    errors: [],
    notifications: [],

}

const locale = "uk_UA"
const apiAdapter = ApiAdapter();

export const getCSRF = createAsyncThunk('Auth/getCSRF',
    async () => {
        // console.log('getCSRF')
        const response = await axios.get(`${baseUrl}/csrf/`, {
            withCredentials: true,
        });
        return response.data;
    }
)

export const logout = createAsyncThunk('Auth/logout',
    async () => {
        const response = await axios.get(`${baseUrl}/logout/`, {
            headers: {
                "X-CSRFToken": Cookies.get("csrftoken")
            },
            withCredentials: true,
        });
        return response.data;
    }
)

export const session = createAsyncThunk('Auth/session',
    async () => {
        // console.log('session')
        const response = await axios.get(`${baseUrl}/session/`, {
            headers: {
                "X-CSRFToken": Cookies.get("csrftoken")
            },
            withCredentials: true,
        });
        return response.data;
    }
)

export const login = createAsyncThunk('Auth/login',
    async (signInData) => {

        const {signIn} = AuthRepository(apiAdapter);
        return await signIn(signInData).then()

    }
)

export const signup = createAsyncThunk('Auth/register',
    async (signUpData) => {
        const {signUp} = AuthRepository(apiAdapter);
        return await signUp(signUpData).then()
    }
)

export const Auth = createSlice({
    name: 'Auth2',
    initialState,
    reducers: {
        setErrors: (state, action) => {
            return {...state, errors: [action.payload]}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                 return signUpInFulfilled(state, action.payload.status, action.payload.data)
            })
            .addCase(login.rejected, (state, action) => {
                return {...state, errors: reportError("OperationErrorTryLater", locale)}
            })
            //register
            .addCase(signup.fulfilled, (state, action) => {
                return signUpInFulfilled(state, action.payload.status, action.payload.data)
            })
            .addCase(signup.rejected, (state, action) => {
                return {...state, errors: reportError("OperationErrorTryLater", locale)}
            })
            //getCSRF
            .addCase(getCSRF.fulfilled, (state, action) => {
                // const csrfToken = action.payload.get("X-CSRFToken");
                // state.isAuthenticated = false
            })
            // session
            .addCase(session.fulfilled, (state, action) => {
                // state.isAuthenticated = action.payload.isAuthenticated
                localStorage.setItem('isAuthenticated', action.payload.isAuthenticated);
                return {...state, isAuthenticated: action.payload.isAuthenticated, isAdmin: action.payload.isAdmin}
            })
            .addCase(session.rejected, (state, action) => {
                // state.isAuthenticated = false
                // localStorage.setItem('isAuthenticated', false);
                localStorage.clear();
                return {...state, isAuthenticated: false, isAdmin: false}
            })
            //logout
            .addCase(logout.fulfilled, (state, action) => {
                localStorage.clear();
                return {...state, isAuthenticated: false}
            })
    }
})

export const {setErrors, setAuth} = Auth.actions
export default Auth.reducer