import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const NextOrder = () => {

    let navigate = useNavigate()

    const data = useSelector(state => state.Dashboard.next_order)

    return(
        <div className="card card-border mb-0 h-100">
            <div className="card-header card-header-action">
                <h5>Найближче відвантаження</h5>
                <div className="card-action-wrap">
                    <button className="btn btn-sm btn-outline-light" onClick={() => {
                        navigate(`/orders/order-details/${data.uid}`)
                    }}>
                        <span>
                            <span className="icon">
                                <span className="feather-icon"></span>
                            </span>
                            <span className="btn-text">Детально...</span>
                        </span>
                    </button>
                </div>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-sm-12 table-responsive">
                        <ul className="list-unstyled">
                            <li className="mb-3">
                                <div className="fs-6">Номер</div>
                                <div className="text-primary fw-medium">{data.number}</div>
                            </li>
                            <li className="mb-3">
                                <div className="fs-6">Дата/час доставки</div>
                                <div className="text-dark fw-medium">{`${data.delivery_date} - ${data.delivery_time}`}</div>
                            </li>
                            <li className="mb-3">
                                <div className="fs-6">Адреса доставки</div>
                                <div className="text-dark fw-medium">{data.delivery_address}</div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default NextOrder