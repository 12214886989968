import {useCallback} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Form, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShareFromSquare} from "@fortawesome/free-regular-svg-icons";
import {
    faBan,
    faFilePdf,
    faDownload
} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";

import {sendPrintOrderRequest, setPrintData} from "../../../Reducers/OrderData";

const FileDocument = () => {

    const b64string = useSelector(state => state.OrderData.printData)
    const filename = 'document.pdf';

    return (
        <>
            <div className="row mb-3">
                {/*<Form>*/}
                {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                            inline
                            label="Файл pdf"
                            name="group1"
                            type={type}
                            defaultChecked={true}
                            id={`inline-${type}-1`}
                        />
                        <Form.Check
                            inline
                            disabled={true}
                            label="e-mail"
                            name="group1"
                            type={type}
                            id={`inline-${type}-2`}
                        />
                        <Form.Check
                            inline
                            disabled={true}
                            label="Telegram"
                            name="group1"
                            type={type}
                            id={`inline-${type}-3`}
                        />
                    </div>
                ))}
                {/*</Form>*/}
            </div>
            {b64string ? (
                <div className="row">
                    <div className="col-lg-10">
                        <div className="d-flex">
                            <FontAwesomeIcon icon={faFilePdf} className="text-primary feather fs-2 me-3"/>
                            <span className="fs-5">{filename}</span>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <a className="btn-icon btn-rounded flush-soft-hover btn btn-flush-dark" download={filename}
                           href={`data:application/pdf;base64,${b64string}`}>
                            <span className="btn-icon-wrap">
                                <span className="feather-icon">
                                    <FontAwesomeIcon icon={faDownload} className="text-yellow feather fs-4 mx-3"/>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>
            ) : <></>}
        </>
    )
}

const PrintWindow = ({show, showHandler, uid}) => {

    const dispatch = useDispatch()
    // const navigate = useNavigate()
    const methods = useForm({
        defaultValues: {
            uid: uid,
        }
    })
    const {
        clearErrors,
        register,
        handleSubmit,
        formState: {errors}
    } = methods

    const onSubmitHandler = useCallback((formValues) => {
        dispatch(sendPrintOrderRequest(formValues))
        // clearErrors('delivery_date')
        // showHandler(false);
    }, [])

    const onCloseHandler = () => {
        dispatch(setPrintData(undefined))
        showHandler(false)
    }

    return (
        <Modal size="md" centered show={show} onHide={() => {
            onCloseHandler()
        }}>
            <FormProvider {...methods}>
                <form noValidate={true} onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="modal-content border-0">
                        <Modal.Header closeButton className="mx-2">
                            <Modal.Title>Друкувати замовлення</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FileDocument/>
                            {/*<label className="form-label">Дата відвантаження</label>*/}
                            {/*<SelectDateForm id={"delivery_date"} name={"delivery_date"} readOnly={false}*/}
                            {/*                onCallback={handleDateRangeCallback}/>*/}
                            {/*<ErrorMessage field={"Дата відвантаження"} type={errors["delivery_date"]?.type}/>*/}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn py-2 btn-sm" type="submit">
                                <FontAwesomeIcon icon={faShareFromSquare}
                                                 className="fs-0 me-2 text-yellow"/>
                                Отримати файл
                            </Button>
                            <Button className="btn bg-warning py-2 btn-sm border-yellow"
                                    onClick={() => {
                                        onCloseHandler()
                                    }}>
                                <FontAwesomeIcon icon={faBan} className="fs-0 me-2 text-primary"/>
                                Скасувати
                            </Button>
                        </Modal.Footer>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default PrintWindow