import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    faMagnifyingGlass
} from "@fortawesome/free-solid-svg-icons";

import {RangeForm} from "../DateRangePicker/DateRangePicker";
import Pagination from "../Pagination/Pagination";
import InWorkTable from "./OrdersTable";
import ErrorMessage from "../Authentication/ErrorMessage";
import AuthContext from "../AuthContext";

import {useForm, FormProvider} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

import {setData, setLoading, reset as resetOrdersState} from "../../Reducers/Orders";
import {reset as resetPagination} from "../../Reducers/Pagination"


import moment from "moment";
import BorderedSpinner from "../Spinner/Spinner";
import ApiAdapter from "../../Adapters/ApiAdapter";
import DataRepository from "../../Repositories/DataRepository";
import locale_ukUA from "../../Utils/drpl_uk-UA";


const ArchiveTable = () => {

    const data = useSelector(state => state.Pagination.currentItems)

    return (
        <InWorkTable data={data}/>
    )
}

function onGetDataHandler(order_number_check, order_number, period_check, start_date, end_date, dispatch) {

    const number = order_number_check === true && order_number.length > 0 ? order_number : '0'

    if (period_check === false) {
        start_date = '00010101'
        end_date = '00010101'
    }
    dispatch(setLoading(true))
    getData(number, start_date, end_date, dispatch, setData).then()
}

const ArchiveOrders = () => {

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const order_number_check = (searchParams.get('order_number_check') === 'true')
        const period_check = (searchParams.get('period_check') === 'true')

        methods.setValue('start_date', moment().format("YYYYMMDD"))
        methods.setValue('end_date', moment().format("YYYYMMDD"))

        if (order_number_check || period_check) {

            methods.setValue('order_number', searchParams.get('order_number'))
            methods.setValue('order_number_check', order_number_check)

            methods.setValue('start_date', searchParams.get('start_date'))
            methods.setValue('end_date', searchParams.get('end_date'))
            methods.setValue('period_check', period_check)

            onGetDataHandler(order_number_check, searchParams.get('order_number'),
                period_check, searchParams.get('start_date'), searchParams.get('end_date'), dispatch)
        }

    }, [searchParams]);

    const dispatch = useDispatch()
    let {isAuthenticated} = useContext(AuthContext)

    const data = useSelector(state => state.Orders.data);
    const loading = useSelector(state => state.Orders.loading)
    // const period = useSelector(state => state.DateRangePicker.period);

    const methods = useForm()
    const {register, handleSubmit, formState: {errors}} = methods

    const onSubmitHandler = (formValues) => {

        if (isAuthenticated === true) {
            setSearchParams(formValues)
        } else {
            navigate("/")
        }
    }

    const handleDateRangeCallback = (start, end) => {
        methods.setValue('start_date', start.format("YYYYMMDD"))
        methods.setValue('end_date', end.format("YYYYMMDD"))
    };

    return (
        <div className="card card-border mb-0 h-100">
            <div className="card-header card-header-action">
                <h6>Замовлення
                    <span className="badge badge-sm badge-light ms-1">{data.length}</span>
                </h6>
                <div className="card-action-wrap">
                    {/*  Free  */}
                </div>
            </div>
            <div className="card-body">
                <div className="contact-list-view">

                    <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                        {/*Head*/}

                        <FormProvider {...methods}>
                            <form noValidate={true} onSubmit={handleSubmit(onSubmitHandler)}>

                                <div className="row">

                                    <div className="col-xxl-5 col-lg-5 mb-1">
                                        <div className="text-left d-flex justify-content-start">
                                            {/*Order number*/}
                                            <div className="form-group me-3">
                                                <label className="form-label">Номер замовлення</label>
                                                <span className="input-affix-wrapper w-180p">
                                                    <div className="input-prefix form-check ">
                                                        <input
                                                            className="form-check-input is-valid mx-0"
                                                            id="order_number_check"
                                                            name="order_number_check"
                                                            type="checkbox"
                                                            {...register("order_number_check", {required: false})}/>
                                                    </div>
                                                    <input className="form-control"
                                                           id="order_number"
                                                           name="order_number"
                                                           type="text"
                                                           placeholder="xxx-xxx-xxx"
                                                           {...register("order_number", {required: false})}/>
                                                    <ErrorMessage field={"Номер"} type={errors["order_number"]?.type}/>
                                                </span>
                                            </div>
                                            {/*Order date*/}
                                            <div className="form-group">
                                                <label className="form-label">Період</label>
                                                <span className="input-affix-wrapper w-270p">
                                                    <span className="input-prefix">
                                                        <div className="input-prefix form-check ">
                                                            <input className="form-check-input is-valid mx-0"
                                                                   id="period_check"
                                                                   name="period_check"
                                                                   type="checkbox"
                                                                   {...register("period_check", {required: false})}/>
                                                        </div>
                                                    </span>
                                                    <RangeForm id={"archive_period"} useMinDate={false}
                                                               onCallback={handleDateRangeCallback}
                                                               initStartDate={searchParams.get('start_date')}
                                                               initEndDate={searchParams.get('end_date')}/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xxl-7 col-lg-7 mb-1">
                                        <div className="text-end">
                                            <button type="submit" className="mt-5 btn btn-primary btn-uppercase">
                                                <span>
                                                    <span className="icon"> <FontAwesomeIcon icon={faMagnifyingGlass}
                                                                                             className="bi me-2"/> </span>
                                                    <span className="btn-text">Знайти</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </FormProvider>

                        {/*...Head*/}
                        {/*Table*/}
                        <div className="row">
                            <div className="col-sm-12 table-responsive">
                                {(() => {
                                        if (loading === false) {
                                            return <ArchiveTable/>
                                        } else
                                            return <div className="my-3 d-flex justify-content-center">
                                                <BorderedSpinner/>
                                            </div>
                                    }
                                )()}
                            </div>
                        </div>
                        {/*...Table*/}
                        {/*Pagination*/}
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div
                                    className="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                                    <Pagination itemsPerPage={10} data={data}/>
                                </div>
                            </div>
                        </div>
                        {/*...Pagination*/}
                    </div>

                </div>
            </div>

        </div>
    )
}

const getData = async (number, start_date, end_date, dispatch) => {

    const apiAdapter = ApiAdapter();
    const {getArchive} = DataRepository(apiAdapter);
    await getArchive(number, start_date, end_date, dispatch, setData)
    dispatch(setLoading(false))
}

const Archive = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetPagination())
        dispatch(resetOrdersState())
        dispatch(setLoading(false))
    }, []);

    return (
        <div className="container-xxl">
            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">Архів замовлень</h1>
            </div>
            <div className="hk-pg-body">
                <div className="row">
                    <div className="col-md-12 mb-md-4 mb-3">
                        <ArchiveOrders/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Archive