const locale = {
    format: "DD.MM.YYYY",
    separator: " - ",
    applyLabel: "Примінити",
    cancelLabel: "Відмінити",
    fromLabel: "Від",
    toLabel: "До",
    customRangeLabel:"Custom",
    weekLabel:"Т",
    daysOfWeek:[
            "Нд",
            "Пн",
            "Вт",
            "Ср",
            "Чт",
            "Пт",
            "Сб"],
    monthNames:[
            "Січень",
            "Лютий",
            "Березень",
            "Квітень",
            "Травень",
            "Червень",
            "Липень",
            "Серпень",
            "Вересень",
            "Жовтень",
            "Листопад",
            "Грудень"],
    firstDay: 1
}

export default locale