import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faHouse} from "@fortawesome/free-solid-svg-icons";

const ControlPanelBase = () => {

    const navigate = useNavigate()

    return (
        <div className="contact-toolbar-left">
            <div className="d-xxl-flex align-items-center mb-0">

                <button type="button" className="btn btn-outline-light btn-sm me-2"
                        onClick={() => {
                            navigate(-1)
                        }}>
                <span>
                    <span className="icon">
                        <span className="feather-icon">
                            <FontAwesomeIcon icon={faArrowLeft} className="text-primary my-1"/>
                        </span>
                    </span>
                </span>
                </button>

                <button type="button" className="btn btn-outline-light btn-sm me-2"
                        onClick={() => {
                            navigate('/')
                        }}>
                <span>
                    <span className="icon">
                        <span className="feather-icon">
                            <FontAwesomeIcon icon={faHouse} className="text-primary my-1"/>
                        </span>
                    </span>
                </span>
                </button>

            </div>
        </div>
    )
}

export default ControlPanelBase