import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    data: {
        profile: {},
        additionalParameters: {},
    }
}

export const User = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setData: (state, action) => {
            return {...state, data: action.payload}
        },
    },
})

export const {
    setData
} = User.actions
export default User.reducer