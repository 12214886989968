import noImage from "../../../img/no_image.png";
import "./image.css"

import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";

import AuthContext from "../../AuthContext";
import axios from "axios";
import Cookies from "js-cookie";

import {setData} from "../../../Reducers/Blog/Blog";
import {setErrors} from "../../../Reducers/Errors";

import {handleError} from "../../../Utils/Errors";
import Pagination from "../../Pagination/Pagination";

const baseUrl = process.env.REACT_APP_BASE_AUTH_URL;

const NewsListArticle = ({rowData}) => {

    const navigate = useNavigate()

    return (
        <div className="card mt-5">

            <div className="row gx-0">
                {
                    rowData.image ?
                        <img className="col-12 col-md-6 order-md-2 news-image" src={`${baseUrl}/${rowData.image}`}/> :
                        <img className="col-12 col-md-6 order-md-2 news-image" src={noImage}/>
                }

                <div className="col-12 col-md-6 px-5">

                    <div className="card-body">

                        <a href={`/news/article/${rowData.permalink}/`}>
                            <h4>
                                {rowData.title}
                            </h4>
                        </a>

                        <p className="mt-5 text-bg-info h-200p">
                            {rowData.text}
                        </p>
                    </div>

                    <div className="card-footer">
                        <div className="row mt-3">

                            <div className="col-auto">
                                <p className="h6 text-uppercase mb-0 ms-auto">
                                    Прес Служба
                                </p>
                            </div>

                            <div className="col-auto">
                                <p className="h6 text-uppercase mb-0 ms-auto">
                                    <time dateTime="2019-05-31">2024 січень 31</time>
                                </p>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

const NewsListArticles = () => {

    const data = useSelector(state => state.Pagination.currentItems)

    return (
        <>
            {
                data.map(row => <NewsListArticle key={row.permalink} rowData={row}/>)
            }
        </>
    )
}

const NewsList = () => {

    const data = useSelector(state => state.Blog.data)
    // console.log("NewsList")
    // const data = [{slug: "1"}, {slug: "2"}, {slug: "3"}, {slug: "4"}, {slug: "5"}, {slug: "6"},]

    return (
        // <div className="blogapp-wrap">
        <div className="blogapp-content">
            <div className="blog-body">
                <div className="post-list">
                    <div className="row mb-3">
                        <div className="col-sm-12 col-md-12">
                            <NewsListArticles/>
                        </div>
                    </div>
                    {/*Pagination*/}
                    <div className="row">
                        <div className="d-flex justify-content-end">
                            <Pagination itemsPerPage={5} data={data}/>
                        </div>
                    </div>
                </div>
                {/*Pagination*/}
            </div>
        </div>

        // </div>
    )
}

const News = () => {


    const dispatch = useDispatch()
    const navigate = useNavigate()

    let {isAuthenticated} = useContext(AuthContext)

    useEffect(() => {
        if (isAuthenticated === true) {
            axios.get(`${baseUrl}/news/news-list/`,
                {
                    timeout: 5000,
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": Cookies.get("csrftoken")
                    }
                }).then(function (response) {

                if (response.status === 200) {

                    const responseData = response.data;

                    switch (responseData.status) {
                        case "SUCCESS":
                            dispatch(setData(responseData.data))
                            break;
                        default:
                            dispatch(setErrors(['undefined error']))
                    }
                }
            }).catch(function (error) {
                const errorList = handleError(error)
                dispatch(setErrors(errorList))
            })
        } else
            navigate("/")

    }, [isAuthenticated]);

    return (
        <div className="container-xxl">
            {/*Header tittle*/}
            <div className="hk-pg-header pg-header-wth-tab pt-7">
                <div className="d-flex">
                    <div className="d-flex flex-wrap justify-content-between flex-1">
                        <div className="mb-lg-0 mb-2 me-8">
                            <h1 className="pg-title">Стрічка новин</h1>
                            {/*<p>До кабінету електронних послуг компаніїї "Віконний стандарт"</p>*/}
                        </div>
                    </div>
                </div>
            </div>
            {/*Header tittle*/}
            <div className="row">
                <div className="col-md-12 mb-md-4 mb-3">
                    <NewsList/>
                </div>
            </div>

        </div>
    )
}

export default News