import {useNavigate} from "react-router-dom";
import {Table} from "react-bootstrap";

const LastNewsTable = ({data}) => {
    return(
        <Table responsive>
            <thead>
            <tr>
                <th className="mnw-175p">Заголовок</th>
                <th className="mnw-75p">Дата</th>
            </tr>
            </thead>
            <tbody>
            {/*{*/}
            {/*    data.map(el => <TableRow key={el.row_id} rowData={el}/>)*/}
            {/*}*/}
            </tbody>
        </Table>
    )
}

const LastNews = () => {

    let navigate = useNavigate()

    return(
        <div className="card card-border mb-0 h-100">
            <div className="card-header card-header-action">
                <h5>Новини</h5>
                <div className="card-action-wrap">
                    <button className="btn btn-sm btn-outline-light" onClick={() =>{navigate('/news')}}>
                        <span>
                            <span className="icon">
                                <span className="feather-icon"></span>
                            </span>
                            <span className="btn-text">Детально...</span>
                        </span>
                    </button>
                </div>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-sm-12 table-responsive">
                        <LastNewsTable/>
                    </div>
                </div>

                {/*Pagination*/}
                {/*Pagination*/}
            </div>

        </div>
    )
}

export default LastNews