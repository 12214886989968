import {repositoryResponse, errorResponse} from "./Response";


const NewsRepository = (apiAdapter) => ({

    getArticle: async (slug, dispatch, setData) => {
        await apiAdapter.get(`/news/article/${slug}/`)
            .then(function (response) {
                repositoryResponse(response.status, response.data, dispatch, setData);
            })
            .catch(function (error) {
                errorResponse(error.response.status, dispatch, error)
            });
    },

});

export default NewsRepository;