import imgLogin from "../../img/img_login.jpg";

const AuthBody = ({children}) =>{
    return(
        <div className={"hk-pg-body"}>
            <div className={"container-xxl"}>
                <div className={"row"}>
                    <div className={"col-xl-7 col-lg-6 d-lg-block d-none v-separator separator-sm"}>
                        <div className="auth-content py-md-0 py-8">
                            <div className="row">
                                <div className="col-xxl-9 col-xl-8 col-lg-11 text-center mx-auto">
                                    <img src={imgLogin} className="img-fluid w-sm-100 w-100 mb-3" alt="login"/>
                                    <p className="w-xxl-65 w-100 mx-auto">Кабінет електронних послуг для дилерів та
                                        клієнтів</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-5 col-lg-6 col-md-7 col-sm-10 position-relative mx-auto"}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthBody