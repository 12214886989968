import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {handleError, reportError} from "../Utils/Errors";
import ApiAdapter from "../Adapters/ApiAdapter";
import DataRepository from "../Repositories/DataRepository";
import axios from "axios";
import Cookies from "js-cookie";

const locale = "uk_UA"

const apiAdapter = ApiAdapter();

const initialState = {
    // timestamp: new Date().toString(),
    data: {
        doc: {
            uid: "",
            number: "",
            amount: 0,
            date: "",
            delivery_date: "",
            execution_date: "",
            delivery_address: "",
            additional_address: "",
            manager: ""
        },
        // update_result: "",
        images: [],
        goods: [],
        services: [],
    },
    errors: [],
    notifications: [],
    printData: undefined
}

function updateRequestFulfilled(state, payload) {
    if (payload.status === 200) {
        return {...state, notifications: payload.data.data.notifications}
    } else {
        return {...state, errors: payload.errors}
    }

}

export const sendAddressUpdateRequest = createAsyncThunk('OrderData/sendAddressUpdateRequest',
    async ({uid, address}) => {

        const {updateDeliveryAddress} = DataRepository(apiAdapter);
        return await updateDeliveryAddress(uid, address).then()

    }
)

export const sendDeliveryDateUpdateRequest = createAsyncThunk('OrderData/sendDeliveryDateUpdateRequest',
    async ({uid, delivery_date}) => {

        const {updateDeliveryDate} = DataRepository(apiAdapter);
        return await updateDeliveryDate(uid, delivery_date).then()

    }
)

export const sendPrintOrderRequest = createAsyncThunk('Orders/sendPrintOrderRequest',
    async ({uid}) => {

        const {getOrderPrintDoc} = DataRepository(apiAdapter);
        return await getOrderPrintDoc(uid).then()

    }
)

export const Orders = createSlice({
    name: 'OrderData',
    initialState,
    reducers: {
        setData: (state, action) => {
            return {...state, data: action.payload}
        },
        setPrintData: (state, action) => {
            return {...state, printData: action.payload}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendAddressUpdateRequest.fulfilled, (state, action) => {
                return updateRequestFulfilled(state, action.payload)
            })
            .addCase(sendAddressUpdateRequest.rejected, (state, action) => {
                return {...state, errors: reportError("OperationErrorTryLater", locale)}
            })
            // sendDeliveryDateUpdateRequest
            .addCase(sendDeliveryDateUpdateRequest.fulfilled, (state, action) => {
                return updateRequestFulfilled(state, action.payload)
            })
            .addCase(sendDeliveryDateUpdateRequest.rejected, (state, action) => {
                return {...state, errors: reportError("OperationErrorTryLater", locale)}
            })
            // sendPrintOrderRequest
            .addCase(sendPrintOrderRequest.fulfilled, (state, action) => {
                if (action.payload.status === 200) {
                    return {...state, printData: action.payload.data.data}
                } else {
                    return {...state, errors: action.payload.errors}
                }
            })
            .addCase(sendPrintOrderRequest.rejected, (state, action) => {
                return {...state, errors: reportError("OperationErrorTryLater", locale)}
            })
    }
})

export const {
    setData,
    setPrintData
} = Orders.actions
export default Orders.reducer