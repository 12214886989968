import {handleError} from "../Utils/Errors";

function responseStatus200(state, responseData) {

    switch (responseData.status) {
        case "SUCCESS":
            localStorage.setItem('isAuthenticated', responseData.data.isAuthenticated);

            if (responseData.data.user_data !== undefined)
                localStorage.setItem('user-data', JSON.stringify(responseData.data.user_data));

            return {...state, isAuthenticated: responseData.data.isAuthenticated, isAdmin: responseData.data.isAdmin}

        default: {
            return {...state, errors: handleError(['UndefinedError'])}
        }
    }
}

function signUpInFulfilled(state, status, responseData) {

    switch (status) {
        case 200: {
            return responseStatus200(state, responseData)
        }
        case 400: {
            return {...state, errors: responseData.errors}
        }
        case 401: {
            return {...state, errors: responseData.errors}
        }
        default: {
            window.location.href = '/';
        }
    }
}

const AuthRepository = (apiAdapter) => ({

    signUp: async (signUpData) => {
        try {
            const response = await apiAdapter.post('/signup/', signUpData)
            return {data: response.data, status: response.status};
        } catch (error) {
            return {data: {errors: handleError(error)}, status: error.response.status}
        }
    },
    signIn: async (signInData) => {
        try {
            const response = await apiAdapter.post('/login/', signInData)
            return {data: response.data, status: response.status};
        } catch (error) {
            return {data: {errors: handleError(error)}, status: error.response.status}
        }
    }

});

export {AuthRepository, signUpInFulfilled};