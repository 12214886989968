import * as React from "react";

const SVGLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2000 650.8"
    // width="1866px"
    // height="697px"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fefefe"
        d="M -0.5,-0.5 C 621.5,-0.5 1243.5,-0.5 1865.5,-0.5C 1865.5,231.833 1865.5,464.167 1865.5,696.5C 1243.5,696.5 621.5,696.5 -0.5,696.5C -0.5,464.167 -0.5,231.833 -0.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3e4b54"
        d="M 1745.5,30.5 C 1758.5,30.5 1771.5,30.5 1784.5,30.5C 1784.5,39.8333 1784.5,49.1667 1784.5,58.5C 1771.5,58.5 1758.5,58.5 1745.5,58.5C 1745.5,49.1667 1745.5,39.8333 1745.5,30.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3d4a53"
        d="M 1005.5,60.5 C 1045.55,58.083 1073.38,75.083 1089,111.5C 1098.12,143.645 1090.62,171.145 1066.5,194C 1039.8,213.52 1011.13,216.853 980.5,204C 946.897,184.279 933.73,155.113 941,116.5C 951.256,84.7362 972.756,66.0696 1005.5,60.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#96d600"
        d="M 54.5,64.5 C 143.167,64.3333 231.834,64.5 320.5,65C 321.894,65.956 322.561,67.2894 322.5,69C 321.324,72.5141 319.491,75.6808 317,78.5C 291.62,110.758 266.12,142.924 240.5,175C 239.167,175.667 237.833,176.333 236.5,177C 214.167,177.333 191.833,177.667 169.5,178C 165.333,179.5 162.5,182.333 161,186.5C 160.978,217.561 160.311,248.561 159,279.5C 125.53,323.604 91.6963,367.437 57.5,411C 53.9416,413.154 50.7749,412.654 48,409.5C 47.3333,296.833 47.3333,184.167 48,71.5C 49.6941,68.6424 51.8607,66.309 54.5,64.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3d4a53"
        d="M 529.5,63.5 C 556.835,63.3334 584.169,63.5 611.5,64C 641.369,69.5059 653.202,87.0059 647,116.5C 643.611,124.555 638.111,130.722 630.5,135C 653.13,149.356 657.63,168.189 644,191.5C 635.904,200.798 625.737,206.298 613.5,208C 585.502,208.5 557.502,208.667 529.5,208.5C 529.5,160.167 529.5,111.833 529.5,63.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3c4952"
        d="M 705.5,63.5 C 714.5,63.5 723.5,63.5 732.5,63.5C 732.5,111.833 732.5,160.167 732.5,208.5C 723.5,208.5 714.5,208.5 705.5,208.5C 705.5,160.167 705.5,111.833 705.5,63.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3d4a52"
        d="M 923.5,207.5 C 911.678,208.495 899.678,208.828 887.5,208.5C 869.531,186.354 851.697,164.021 834,141.5C 827.031,147.468 820.364,153.801 814,160.5C 813.5,176.497 813.333,192.497 813.5,208.5C 804.5,208.5 795.5,208.5 786.5,208.5C 786.5,160.167 786.5,111.833 786.5,63.5C 795.5,63.5 804.5,63.5 813.5,63.5C 813.333,85.1692 813.5,106.836 814,128.5C 836.197,106.635 858.697,85.1353 881.5,64C 893.5,63.3333 905.5,63.3333 917.5,64C 896.656,83.3449 875.989,102.845 855.5,122.5C 877.946,151.086 900.612,179.419 923.5,207.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#566169"
        d="M 1159.5,123.5 C 1158.5,104.007 1158.17,84.3404 1158.5,64.5C 1149.83,64.5 1141.17,64.5 1132.5,64.5C 1132.5,112.5 1132.5,160.5 1132.5,208.5C 1131.5,160.336 1131.17,112.003 1131.5,63.5C 1140.83,63.5 1150.17,63.5 1159.5,63.5C 1159.5,83.5 1159.5,103.5 1159.5,123.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3c4952"
        d="M 1159.5,123.5 C 1184.5,123.5 1209.5,123.5 1234.5,123.5C 1235.5,104.007 1235.83,84.3404 1235.5,64.5C 1244.17,64.5 1252.83,64.5 1261.5,64.5C 1261.5,112.5 1261.5,160.5 1261.5,208.5C 1252.5,208.5 1243.5,208.5 1234.5,208.5C 1234.5,188.833 1234.5,169.167 1234.5,149.5C 1209.5,149.5 1184.5,149.5 1159.5,149.5C 1159.5,169.167 1159.5,188.833 1159.5,208.5C 1150.5,208.5 1141.5,208.5 1132.5,208.5C 1132.5,160.5 1132.5,112.5 1132.5,64.5C 1141.17,64.5 1149.83,64.5 1158.5,64.5C 1158.17,84.3404 1158.5,104.007 1159.5,123.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#4f5c63"
        d="M 1261.5,208.5 C 1261.5,160.5 1261.5,112.5 1261.5,64.5C 1252.83,64.5 1244.17,64.5 1235.5,64.5C 1235.83,84.3404 1235.5,104.007 1234.5,123.5C 1234.5,103.5 1234.5,83.5 1234.5,63.5C 1243.83,63.5 1253.17,63.5 1262.5,63.5C 1262.83,112.003 1262.5,160.336 1261.5,208.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3c4952"
        d="M 1348.5,63.5 C 1348.5,83.5 1348.5,103.5 1348.5,123.5C 1374.01,123.831 1399.34,123.498 1424.5,122.5C 1424.5,102.833 1424.5,83.1667 1424.5,63.5C 1433.5,63.5 1442.5,63.5 1451.5,63.5C 1451.5,111.833 1451.5,160.167 1451.5,208.5C 1442.5,208.5 1433.5,208.5 1424.5,208.5C 1424.5,188.833 1424.5,169.167 1424.5,149.5C 1399.5,149.5 1374.5,149.5 1349.5,149.5C 1348.5,168.993 1348.17,188.66 1348.5,208.5C 1339.5,208.5 1330.5,208.5 1321.5,208.5C 1321.5,160.167 1321.5,111.833 1321.5,63.5C 1330.5,63.5 1339.5,63.5 1348.5,63.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#7e878e"
        d="M 1451.5,63.5 C 1452.5,111.997 1452.83,160.664 1452.5,209.5C 1442.99,209.827 1433.65,209.494 1424.5,208.5C 1433.5,208.5 1442.5,208.5 1451.5,208.5C 1451.5,160.167 1451.5,111.833 1451.5,63.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3c4952"
        d="M 1511.5,63.5 C 1520.5,63.5 1529.5,63.5 1538.5,63.5C 1538.33,99.5015 1538.5,135.502 1539,171.5C 1563.83,135.833 1588.67,100.167 1613.5,64.5C 1623.81,63.5014 1634.15,63.1681 1644.5,63.5C 1644.5,111.833 1644.5,160.167 1644.5,208.5C 1635.5,208.5 1626.5,208.5 1617.5,208.5C 1617.67,172.832 1617.5,137.165 1617,101.5C 1591.92,136.658 1567.09,171.992 1542.5,207.5C 1532.19,208.499 1521.85,208.832 1511.5,208.5C 1511.5,160.167 1511.5,111.833 1511.5,63.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3d4a53"
        d="M 1831.5,63.5 C 1831.5,111.833 1831.5,160.167 1831.5,208.5C 1822.5,208.5 1813.5,208.5 1804.5,208.5C 1804.67,172.832 1804.5,137.165 1804,101.5C 1779.17,137 1754.33,172.5 1729.5,208C 1719.17,208.5 1708.84,208.666 1698.5,208.5C 1698.5,160.167 1698.5,111.833 1698.5,63.5C 1707.83,63.5 1717.17,63.5 1726.5,63.5C 1726.33,99.1682 1726.5,134.835 1727,170.5C 1751.71,134.913 1776.54,99.4126 1801.5,64C 1811.49,63.5002 1821.49,63.3335 1831.5,63.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#8a9196"
        d="M 1831.5,63.5 C 1832.5,111.997 1832.83,160.664 1832.5,209.5C 1822.99,209.827 1813.65,209.494 1804.5,208.5C 1813.5,208.5 1822.5,208.5 1831.5,208.5C 1831.5,160.167 1831.5,111.833 1831.5,63.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#96d602"
        d="M 448.5,177.5 C 443.5,177.5 438.5,177.5 433.5,177.5C 433.5,261.167 433.5,344.833 433.5,428.5C 432.506,434.995 428.839,439.162 422.5,441C 314.167,441.667 205.833,441.667 97.5,441C 92.9101,438.129 92.4101,434.629 96,430.5C 118.715,402.12 141.049,373.453 163,344.5C 165.383,341.727 168.217,339.561 171.5,338C 217.775,336.681 264.108,336.015 310.5,336C 315.876,333.96 319.043,330.127 320,324.5C 320.5,275.501 320.667,226.501 320.5,177.5C 314.824,177.666 309.157,177.499 303.5,177C 300.651,174.441 299.817,171.274 301,167.5C 324.667,137.167 348.333,106.833 372,76.5C 376.644,73.3851 380.644,74.0517 384,78.5C 406.619,108.12 429.619,137.454 453,166.5C 455.864,172.079 454.364,175.746 448.5,177.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fdfdfd"
        d="M 1005.5,86.5 C 1034.64,83.6368 1053.8,95.9701 1063,123.5C 1067.01,144.999 1060.84,162.833 1044.5,177C 1020.03,191.948 997.867,189.115 978,168.5C 968.854,155.579 965.521,141.246 968,125.5C 970.884,112.964 977.384,102.797 987.5,95C 993.198,91.3155 999.198,88.4822 1005.5,86.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fafafb"
        d="M 556.5,86.5 C 572.17,86.3334 587.837,86.5001 603.5,87C 621.076,91.0115 625.576,101.178 617,117.5C 614.592,119.956 611.759,121.789 608.5,123C 591.223,124.444 573.889,124.944 556.5,124.5C 556.5,111.833 556.5,99.1667 556.5,86.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#aaafb3"
        d="M 1348.5,63.5 C 1349.5,82.9929 1349.83,102.66 1349.5,122.5C 1374.5,122.5 1399.5,122.5 1424.5,122.5C 1399.34,123.498 1374.01,123.831 1348.5,123.5C 1348.5,103.5 1348.5,83.5 1348.5,63.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fefefe"
        d="M 556.5,147.5 C 573.503,147.333 590.503,147.5 607.5,148C 621.421,152.343 625.921,161.51 621,175.5C 618.708,179.788 615.208,182.622 610.5,184C 592.547,185.431 574.547,185.931 556.5,185.5C 556.5,172.833 556.5,160.167 556.5,147.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#979da2"
        d="M 1349.5,149.5 C 1349.5,169.5 1349.5,189.5 1349.5,209.5C 1339.99,209.827 1330.65,209.494 1321.5,208.5C 1330.5,208.5 1339.5,208.5 1348.5,208.5C 1348.17,188.66 1348.5,168.993 1349.5,149.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#ebf7cf"
        d="M 448.5,177.5 C 444.03,178.486 439.363,178.819 434.5,178.5C 434.833,262.002 434.499,345.335 433.5,428.5C 433.5,344.833 433.5,261.167 433.5,177.5C 438.5,177.5 443.5,177.5 448.5,177.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#aab0b4"
        d="M 923.5,207.5 C 924.043,207.56 924.376,207.893 924.5,208.5C 912.006,209.825 899.673,209.825 887.5,208.5C 899.678,208.828 911.678,208.495 923.5,207.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3d4a53"
        d="M 674.5,341.5 C 665.225,343.473 655.558,344.14 645.5,343.5C 634.701,324.767 618.367,316.6 596.5,319C 569.172,327.344 556.338,345.844 558,374.5C 563.495,405.004 581.661,419.504 612.5,418C 627.002,415.324 637.668,407.491 644.5,394.5C 654.817,393.334 665.151,393.168 675.5,394C 664.915,423.566 643.915,440.233 612.5,444C 582.162,445.747 558.329,434.247 541,409.5C 525.768,382.126 525.768,354.793 541,327.5C 565.46,295.24 596.96,285.74 635.5,299C 654.794,307.442 667.794,321.609 674.5,341.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3d4a53"
        d="M 936.5,317.5 C 937.499,311.258 937.833,304.258 937.5,296.5C 946.167,296.5 954.833,296.5 963.5,296.5C 963.5,344.5 963.5,392.5 963.5,440.5C 954.5,440.5 945.5,440.5 936.5,440.5C 936.83,433.468 936.497,426.468 935.5,419.5C 934.893,419.624 934.56,419.957 934.5,420.5C 920.058,437.875 901.391,445.708 878.5,444C 849.993,438.49 831.16,421.99 822,394.5C 815.154,369.979 817.821,346.645 830,324.5C 853.23,292.651 882.73,285.151 918.5,302C 924.962,306.628 930.962,311.795 936.5,317.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3d4952"
        d="M 1476.5,317.5 C 1476.56,318.043 1476.89,318.376 1477.5,318.5C 1478.5,311.197 1478.83,303.863 1478.5,296.5C 1486.83,296.5 1495.17,296.5 1503.5,296.5C 1503.5,344.5 1503.5,392.5 1503.5,440.5C 1494.83,440.5 1486.17,440.5 1477.5,440.5C 1477.83,433.468 1477.5,426.468 1476.5,419.5C 1475.89,419.624 1475.56,419.957 1475.5,420.5C 1462.68,435.826 1446.18,443.826 1426,444.5C 1398.96,442.492 1379.3,429.492 1367,405.5C 1356.68,379.965 1357.01,354.631 1368,329.5C 1384.95,300.284 1410.12,288.784 1443.5,295C 1456.93,299.039 1467.93,306.539 1476.5,317.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3d4952"
        d="M 1576.5,421.5 C 1576.22,420.711 1575.72,420.044 1575,419.5C 1574.5,443.164 1574.33,466.831 1574.5,490.5C 1565.5,490.5 1556.5,490.5 1547.5,490.5C 1547.5,425.833 1547.5,361.167 1547.5,296.5C 1556.17,296.5 1564.83,296.5 1573.5,296.5C 1573.33,303.841 1573.5,311.174 1574,318.5C 1598.15,290.857 1626.65,285.69 1659.5,303C 1681.16,318.487 1692.16,339.654 1692.5,366.5C 1693.28,397.461 1680.61,420.961 1654.5,437C 1624.61,450.257 1598.61,445.09 1576.5,421.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#8b9398"
        d="M 1781.5,322.5 C 1797.99,321.503 1814.66,321.17 1831.5,321.5C 1831.5,313.167 1831.5,304.833 1831.5,296.5C 1788.83,296.5 1746.17,296.5 1703.5,296.5C 1703.5,304.833 1703.5,313.167 1703.5,321.5C 1720.34,321.17 1737.01,321.503 1753.5,322.5C 1736.5,322.5 1719.5,322.5 1702.5,322.5C 1702.5,313.5 1702.5,304.5 1702.5,295.5C 1745.83,295.5 1789.17,295.5 1832.5,295.5C 1832.5,304.5 1832.5,313.5 1832.5,322.5C 1815.5,322.5 1798.5,322.5 1781.5,322.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3c4952"
        d="M 679.5,296.5 C 722.5,296.5 765.5,296.5 808.5,296.5C 808.5,304.833 808.5,313.167 808.5,321.5C 791.5,321.5 774.5,321.5 757.5,321.5C 757.5,361.167 757.5,400.833 757.5,440.5C 748.5,440.5 739.5,440.5 730.5,440.5C 730.5,401.167 730.5,361.833 730.5,322.5C 713.5,322.5 696.5,322.5 679.5,322.5C 679.5,313.833 679.5,305.167 679.5,296.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#b9bdc1"
        d="M 963.5,296.5 C 954.833,296.5 946.167,296.5 937.5,296.5C 937.833,304.258 937.499,311.258 936.5,317.5C 936.5,310.167 936.5,302.833 936.5,295.5C 945.682,295.173 954.682,295.507 963.5,296.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#959da1"
        d="M 1031.5,296.5 C 1022.5,296.5 1013.5,296.5 1004.5,296.5C 1004.5,344.5 1004.5,392.5 1004.5,440.5C 1013.5,440.5 1022.5,440.5 1031.5,440.5C 1031.5,440.833 1031.5,441.167 1031.5,441.5C 1022.17,441.5 1012.83,441.5 1003.5,441.5C 1003.5,392.833 1003.5,344.167 1003.5,295.5C 1013.01,295.173 1022.35,295.506 1031.5,296.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3c4952"
        d="M 1031.5,296.5 C 1031.5,316.167 1031.5,335.833 1031.5,355.5C 1056.5,355.5 1081.5,355.5 1106.5,355.5C 1107.5,336.007 1107.83,316.34 1107.5,296.5C 1116.5,296.5 1125.5,296.5 1134.5,296.5C 1134.5,344.833 1134.5,393.167 1134.5,441.5C 1125.5,441.5 1116.5,441.5 1107.5,441.5C 1107.5,421.5 1107.5,401.5 1107.5,381.5C 1082.17,381.5 1056.83,381.5 1031.5,381.5C 1031.5,401.167 1031.5,420.833 1031.5,440.5C 1022.5,440.5 1013.5,440.5 1004.5,440.5C 1004.5,392.5 1004.5,344.5 1004.5,296.5C 1013.5,296.5 1022.5,296.5 1031.5,296.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#b9bdc1"
        d="M 1134.5,441.5 C 1134.5,393.167 1134.5,344.833 1134.5,296.5C 1125.5,296.5 1116.5,296.5 1107.5,296.5C 1107.83,316.34 1107.5,336.007 1106.5,355.5C 1106.5,335.5 1106.5,315.5 1106.5,295.5C 1116.17,295.5 1125.83,295.5 1135.5,295.5C 1135.83,344.336 1135.5,393.003 1134.5,441.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3d4952"
        d="M 1341.5,417.5 C 1341.5,425.167 1341.5,432.833 1341.5,440.5C 1282.83,440.5 1224.17,440.5 1165.5,440.5C 1165.5,432.833 1165.5,425.167 1165.5,417.5C 1172.2,417.83 1178.87,417.497 1185.5,416.5C 1193.99,404.191 1198.83,390.525 1200,375.5C 1200.5,348.835 1200.67,322.169 1200.5,295.5C 1240.17,295.5 1279.83,295.5 1319.5,295.5C 1319.5,336.167 1319.5,376.833 1319.5,417.5C 1326.83,417.5 1334.17,417.5 1341.5,417.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#b0b5b9"
        d="M 1504.5,296.5 C 1504.17,296.5 1503.83,296.5 1503.5,296.5C 1495.17,296.5 1486.83,296.5 1478.5,296.5C 1478.83,303.863 1478.5,311.197 1477.5,318.5C 1476.89,318.376 1476.56,318.043 1476.5,317.5C 1477.49,310.352 1477.82,303.019 1477.5,295.5C 1486.68,295.173 1495.68,295.507 1504.5,296.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#59636b"
        d="M 1503.5,296.5 C 1503.83,296.5 1504.17,296.5 1504.5,296.5C 1504.5,344.833 1504.5,393.167 1504.5,441.5C 1495.17,441.5 1485.83,441.5 1476.5,441.5C 1476.82,434.313 1476.49,427.313 1475.5,420.5C 1475.56,419.957 1475.89,419.624 1476.5,419.5C 1477.5,426.468 1477.83,433.468 1477.5,440.5C 1486.17,440.5 1494.83,440.5 1503.5,440.5C 1503.5,392.5 1503.5,344.5 1503.5,296.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#c7cace"
        d="M 1573.5,296.5 C 1564.83,296.5 1556.17,296.5 1547.5,296.5C 1547.5,361.167 1547.5,425.833 1547.5,490.5C 1546.5,425.669 1546.17,360.669 1546.5,295.5C 1555.68,295.173 1564.68,295.507 1573.5,296.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3c4952"
        d="M 1781.5,322.5 C 1780.5,361.663 1780.17,400.996 1780.5,440.5C 1771.83,440.5 1763.17,440.5 1754.5,440.5C 1754.83,400.996 1754.5,361.663 1753.5,322.5C 1737.01,321.503 1720.34,321.17 1703.5,321.5C 1703.5,313.167 1703.5,304.833 1703.5,296.5C 1746.17,296.5 1788.83,296.5 1831.5,296.5C 1831.5,304.833 1831.5,313.167 1831.5,321.5C 1814.66,321.17 1797.99,321.503 1781.5,322.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fdfdfd"
        d="M 883.5,318.5 C 911.72,317.235 929.22,330.235 936,357.5C 939.006,376.954 933.839,393.787 920.5,408C 903.141,420.766 885.141,421.766 866.5,411C 856.708,403.521 850.541,393.688 848,381.5C 842.282,350.243 854.115,329.243 883.5,318.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fefefe"
        d="M 1291.5,319.5 C 1291.5,351.833 1291.5,384.167 1291.5,416.5C 1266.83,416.5 1242.17,416.5 1217.5,416.5C 1222.05,406.636 1225.22,396.303 1227,385.5C 1228.32,363.522 1228.82,341.522 1228.5,319.5C 1249.5,319.5 1270.5,319.5 1291.5,319.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fdfdfd"
        d="M 1424.5,318.5 C 1452.72,317.235 1470.22,330.235 1477,357.5C 1479.64,374.429 1475.98,389.762 1466,403.5C 1454.31,415.286 1440.14,420.12 1423.5,418C 1404.93,413.091 1393.43,401.258 1389,382.5C 1383.88,359.783 1390.04,340.949 1407.5,326C 1413.03,322.894 1418.7,320.394 1424.5,318.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fdfdfe"
        d="M 1611.5,318.5 C 1637.11,316.962 1653.94,328.295 1662,352.5C 1666.11,370.018 1663.44,386.352 1654,401.5C 1637.16,419.574 1617.66,423.074 1595.5,412C 1584,404.176 1577.17,393.343 1575,379.5C 1571.3,359.274 1576.8,342.107 1591.5,328C 1597.6,323.448 1604.27,320.281 1611.5,318.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#939a9f"
        d="M 679.5,296.5 C 722.663,295.501 765.997,295.168 809.5,295.5C 809.5,304.5 809.5,313.5 809.5,322.5C 792.5,322.5 775.5,322.5 758.5,322.5C 758.5,362.167 758.5,401.833 758.5,441.5C 748.985,441.827 739.652,441.494 730.5,440.5C 739.5,440.5 748.5,440.5 757.5,440.5C 757.5,400.833 757.5,361.167 757.5,321.5C 774.5,321.5 791.5,321.5 808.5,321.5C 808.5,313.167 808.5,304.833 808.5,296.5C 765.5,296.5 722.5,296.5 679.5,296.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#5d686f"
        d="M 1753.5,322.5 C 1754.5,361.663 1754.83,400.996 1754.5,440.5C 1763.17,440.5 1771.83,440.5 1780.5,440.5C 1780.17,400.996 1780.5,361.663 1781.5,322.5C 1781.5,362.167 1781.5,401.833 1781.5,441.5C 1772.17,441.5 1762.83,441.5 1753.5,441.5C 1753.5,401.833 1753.5,362.167 1753.5,322.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#a6adb1"
        d="M 674.5,341.5 C 675.404,342.209 675.737,343.209 675.5,344.5C 665.319,344.827 655.319,344.494 645.5,343.5C 655.558,344.14 665.225,343.473 674.5,341.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#d4d7d8"
        d="M 1107.5,441.5 C 1106.5,422.007 1106.17,402.34 1106.5,382.5C 1081.83,382.5 1057.17,382.5 1032.5,382.5C 1032.83,402.34 1032.5,422.007 1031.5,441.5C 1031.5,441.167 1031.5,440.833 1031.5,440.5C 1031.5,420.833 1031.5,401.167 1031.5,381.5C 1056.83,381.5 1082.17,381.5 1107.5,381.5C 1107.5,401.5 1107.5,421.5 1107.5,441.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#8f979c"
        d="M 1291.5,319.5 C 1292.5,351.996 1292.83,384.662 1292.5,417.5C 1267.33,417.831 1242.33,417.498 1217.5,416.5C 1242.17,416.5 1266.83,416.5 1291.5,416.5C 1291.5,384.167 1291.5,351.833 1291.5,319.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#b5b9bd"
        d="M 1576.5,421.5 C 1575.83,444.5 1575.17,467.5 1574.5,490.5C 1574.33,466.831 1574.5,443.164 1575,419.5C 1575.72,420.044 1576.22,420.711 1576.5,421.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#6f7980"
        d="M 934.5,420.5 C 934.56,419.957 934.893,419.624 935.5,419.5C 936.497,426.468 936.83,433.468 936.5,440.5C 945.5,440.5 954.5,440.5 963.5,440.5C 954.348,441.494 945.015,441.827 935.5,441.5C 935.825,434.313 935.491,427.313 934.5,420.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#7e878d"
        d="M 1341.5,417.5 C 1342.49,425.316 1342.83,433.316 1342.5,441.5C 1283.33,441.832 1224.33,441.499 1165.5,440.5C 1224.17,440.5 1282.83,440.5 1341.5,440.5C 1341.5,432.833 1341.5,425.167 1341.5,417.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#404c55"
        d="M 518.5,533.5 C 520.5,533.5 522.5,533.5 524.5,533.5C 525.523,536.393 525.523,539.226 524.5,542C 513.766,544.017 503.099,546.35 492.5,549C 475.638,553.916 467.471,565.083 468,582.5C 477.556,568.869 490.39,564.369 506.5,569C 524.765,576.354 531.932,589.854 528,609.5C 525.877,620.957 519.377,628.791 508.5,633C 480.161,640.086 463.661,629.252 459,600.5C 458.169,590.455 458.502,580.455 460,570.5C 464.405,555.762 473.905,545.928 488.5,541C 498.749,538.962 508.749,536.462 518.5,533.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3f4c55"
        d="M 1050.5,624.5 C 1049.96,624.56 1049.62,624.893 1049.5,625.5C 1066.15,626.499 1082.82,626.833 1099.5,626.5C 1099.5,629.167 1099.5,631.833 1099.5,634.5C 1077.83,634.5 1056.17,634.5 1034.5,634.5C 1034.34,632.143 1034.5,629.81 1035,627.5C 1049.64,613.863 1063.97,599.863 1078,585.5C 1085.15,577.425 1086.81,568.425 1083,558.5C 1074.15,550.578 1063.98,548.412 1052.5,552C 1047.2,553.809 1042.53,556.642 1038.5,560.5C 1036.5,558.833 1034.5,557.167 1032.5,555.5C 1037.68,549.07 1044.35,544.903 1052.5,543C 1059.83,542.333 1067.17,542.333 1074.5,543C 1094.63,549.783 1100.13,562.95 1091,582.5C 1078.36,597.306 1064.86,611.306 1050.5,624.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#404d56"
        d="M 1152.5,542.5 C 1179.12,540.606 1194.28,552.94 1198,579.5C 1199.93,596.466 1195.93,611.799 1186,625.5C 1171.09,637.673 1155.59,638.506 1139.5,628C 1127,613.882 1122.17,597.382 1125,578.5C 1127.22,560.995 1136.39,548.995 1152.5,542.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#404d55"
        d="M 1253.5,542.5 C 1279.64,540.457 1294.8,552.457 1299,578.5C 1301.1,593.417 1298.44,607.417 1291,620.5C 1281.82,632.566 1269.65,637.399 1254.5,635C 1238.83,629.315 1229.66,618.149 1227,601.5C 1226.33,592.5 1226.33,583.5 1227,574.5C 1229.98,558.83 1238.81,548.163 1253.5,542.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#404d56"
        d="M 45.5,542.5 C 60.837,542.333 76.1703,542.5 91.5,543C 112.893,547.288 119.393,559.455 111,579.5C 108.292,583.104 104.792,585.604 100.5,587C 118.97,593.446 124.137,605.612 116,623.5C 112.245,628.295 107.412,631.462 101.5,633C 82.8704,634.404 64.2037,634.904 45.5,634.5C 45.5,603.833 45.5,573.167 45.5,542.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3e4b53"
        d="M 1377.5,581.5 C 1380.5,581.5 1383.5,581.5 1386.5,581.5C 1386.5,588.5 1386.5,595.5 1386.5,602.5C 1392.5,602.5 1398.5,602.5 1404.5,602.5C 1404.5,605.167 1404.5,607.833 1404.5,610.5C 1398.5,610.5 1392.5,610.5 1386.5,610.5C 1386.5,618.5 1386.5,626.5 1386.5,634.5C 1383.5,634.5 1380.5,634.5 1377.5,634.5C 1377.5,626.5 1377.5,618.5 1377.5,610.5C 1360.17,610.5 1342.83,610.5 1325.5,610.5C 1325.21,607.351 1325.71,604.351 1327,601.5C 1342.74,582.349 1358.24,563.016 1373.5,543.5C 1377.12,542.341 1380.79,542.175 1384.5,543C 1368.98,562.356 1353.64,581.856 1338.5,601.5C 1351.48,602.499 1364.48,602.832 1377.5,602.5C 1377.5,595.5 1377.5,588.5 1377.5,581.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fcfcfc"
        d="M 55.5,550.5 C 67.1714,550.333 78.8381,550.5 90.5,551C 104.179,554.857 108.345,563.357 103,576.5C 99.846,580.661 95.6794,583.161 90.5,584C 78.8381,584.5 67.1714,584.667 55.5,584.5C 55.5,573.167 55.5,561.833 55.5,550.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fdfdfd"
        d="M 1156.5,550.5 C 1174.93,550.754 1185.43,560.087 1188,578.5C 1189.29,589.076 1188.29,599.409 1185,609.5C 1179.25,622.959 1169.09,628.459 1154.5,626C 1144.46,622.282 1138.29,615.115 1136,604.5C 1133.67,592.729 1134.01,581.062 1137,569.5C 1140.8,560.199 1147.3,553.865 1156.5,550.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fdfdfd"
        d="M 1257.5,550.5 C 1275.45,550.605 1285.95,559.605 1289,577.5C 1291.05,592.171 1288.38,605.837 1281,618.5C 1274.03,626.019 1265.53,628.519 1255.5,626C 1245.47,621.823 1239.3,614.323 1237,603.5C 1234.42,591.282 1235.09,579.282 1239,567.5C 1242.86,559.135 1249.02,553.469 1257.5,550.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3f4c55"
        d="M 270.5,623.5 C 269.501,634.752 269.168,646.752 269.5,659.5C 266.5,659.5 263.5,659.5 260.5,659.5C 260.5,628.167 260.5,596.833 260.5,565.5C 263.5,565.5 266.5,565.5 269.5,565.5C 269.334,569.846 269.501,574.179 270,578.5C 278.68,567.047 290.18,562.88 304.5,566C 325.815,573.455 333.982,588.289 329,610.5C 322.544,629.158 309.378,637.325 289.5,635C 281.884,633.196 275.55,629.363 270.5,623.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#404d56"
        d="M 383.5,565.5 C 417.803,563.959 431.97,579.959 426,613.5C 419.466,628.108 408.133,635.441 392,635.5C 375.338,634.004 364.338,625.337 359,609.5C 355.501,588.153 363.667,573.486 383.5,565.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#414d56"
        d="M 921.5,564.5 C 931.341,563.862 940.674,565.695 949.5,570C 956.562,575.348 958.395,582.181 955,590.5C 952.079,594.092 948.579,596.925 944.5,599C 958.701,603.572 962.201,612.405 955,625.5C 946.757,632.78 937.091,636.113 926,635.5C 916.831,635.443 908.497,632.777 901,627.5C 901.079,625.154 901.912,622.987 903.5,621C 908.533,623.35 913.866,625.35 919.5,627C 930.374,629.37 939.874,626.87 948,619.5C 950.775,609.578 946.942,604.078 936.5,603C 930.509,602.501 924.509,602.334 918.5,602.5C 918.5,600.167 918.5,597.833 918.5,595.5C 924.176,595.666 929.843,595.499 935.5,595C 945.172,593.704 948.672,588.537 946,579.5C 937.533,572.678 928.033,570.845 917.5,574C 913.632,575.011 909.966,576.511 906.5,578.5C 904.929,576.029 903.929,573.363 903.5,570.5C 909.515,568.273 915.515,566.273 921.5,564.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#404c55"
        d="M 1483.5,565.5 C 1486.5,565.5 1489.5,565.5 1492.5,565.5C 1492.33,569.514 1492.5,573.514 1493,577.5C 1502.02,566.494 1513.52,562.66 1527.5,566C 1543.95,571.058 1552.62,582.224 1553.5,599.5C 1550.96,624.868 1536.96,636.702 1511.5,635C 1503.69,633.175 1497.52,629.009 1493,622.5C 1492.5,634.829 1492.33,647.162 1492.5,659.5C 1489.5,659.5 1486.5,659.5 1483.5,659.5C 1483.5,628.167 1483.5,596.833 1483.5,565.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#414d56"
        d="M 1606.5,565.5 C 1638.36,563.197 1652.86,577.864 1650,609.5C 1643.15,629.024 1629.31,637.524 1608.5,635C 1588.17,628.654 1579,614.821 1581,593.5C 1584.14,579.196 1592.64,569.863 1606.5,565.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#404c55"
        d="M 164.5,565.5 C 164.167,583.845 164.5,602.179 165.5,620.5C 166.107,620.376 166.44,620.043 166.5,619.5C 181.144,601.853 195.81,584.186 210.5,566.5C 213.428,565.516 216.428,565.183 219.5,565.5C 219.5,588.5 219.5,611.5 219.5,634.5C 216.5,634.5 213.5,634.5 210.5,634.5C 210.667,616.497 210.5,598.497 210,580.5C 194.833,598.333 179.667,616.167 164.5,634C 161.518,634.498 158.518,634.665 155.5,634.5C 155.5,611.5 155.5,588.5 155.5,565.5C 158.5,565.5 161.5,565.5 164.5,565.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#6b757c"
        d="M 164.5,565.5 C 165.167,583.5 165.833,601.5 166.5,619.5C 166.44,620.043 166.107,620.376 165.5,620.5C 164.5,602.179 164.167,583.845 164.5,565.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3d4a53"
        d="M 572.5,595.5 C 586.99,596.496 601.657,596.829 616.5,596.5C 616.5,586.167 616.5,575.833 616.5,565.5C 619.5,565.5 622.5,565.5 625.5,565.5C 625.5,588.5 625.5,611.5 625.5,634.5C 622.5,634.5 619.5,634.5 616.5,634.5C 616.5,624.167 616.5,613.833 616.5,603.5C 601.657,603.171 586.99,603.504 572.5,604.5C 572.5,614.5 572.5,624.5 572.5,634.5C 569.5,634.5 566.5,634.5 563.5,634.5C 563.5,611.5 563.5,588.5 563.5,565.5C 566.5,565.5 569.5,565.5 572.5,565.5C 572.5,575.5 572.5,585.5 572.5,595.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3d4a53"
        d="M 666.5,565.5 C 669.5,565.5 672.5,565.5 675.5,565.5C 675.333,583.503 675.5,601.503 676,619.5C 691.167,601.667 706.333,583.833 721.5,566C 724.146,565.503 726.813,565.336 729.5,565.5C 729.5,588.5 729.5,611.5 729.5,634.5C 726.5,634.5 723.5,634.5 720.5,634.5C 720.667,616.497 720.5,598.497 720,580.5C 704.979,598.02 690.145,615.687 675.5,633.5C 672.572,634.484 669.572,634.817 666.5,634.5C 666.5,611.5 666.5,588.5 666.5,565.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#69737a"
        d="M 780.5,595.5 C 779.506,586.014 779.173,576.348 779.5,566.5C 776.833,566.5 774.167,566.5 771.5,566.5C 771.5,589.167 771.5,611.833 771.5,634.5C 770.502,611.673 770.169,588.673 770.5,565.5C 773.833,565.5 777.167,565.5 780.5,565.5C 780.5,575.5 780.5,585.5 780.5,595.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3f4c54"
        d="M 795.5,604.5 C 790.36,603.512 785.026,603.179 779.5,603.5C 779.5,613.833 779.5,624.167 779.5,634.5C 776.833,634.5 774.167,634.5 771.5,634.5C 771.5,611.833 771.5,589.167 771.5,566.5C 774.167,566.5 776.833,566.5 779.5,566.5C 779.173,576.348 779.506,586.014 780.5,595.5C 785.844,595.666 791.177,595.499 796.5,595C 804.047,585.408 811.713,575.908 819.5,566.5C 822.833,565.167 826.167,565.167 829.5,566.5C 820.833,577.5 812.167,588.5 803.5,599.5C 812.644,611.145 821.977,622.645 831.5,634C 827.833,634.667 824.167,634.667 820.5,634C 812.505,623.817 804.171,613.984 795.5,604.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#bdc1c4"
        d="M 1483.5,565.5 C 1483.5,596.833 1483.5,628.167 1483.5,659.5C 1486.5,659.5 1489.5,659.5 1492.5,659.5C 1489.38,660.479 1486.04,660.813 1482.5,660.5C 1482.17,628.662 1482.5,596.996 1483.5,565.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#3f4c55"
        d="M 1685.5,565.5 C 1688.5,565.5 1691.5,565.5 1694.5,565.5C 1694.5,575.5 1694.5,585.5 1694.5,595.5C 1700.21,595.829 1705.87,595.495 1711.5,594.5C 1719.29,585.092 1726.95,575.592 1734.5,566C 1737.83,565.333 1741.17,565.333 1744.5,566C 1735.5,577 1726.5,588 1717.5,599C 1727.17,610.667 1736.83,622.333 1746.5,634C 1742.83,634.667 1739.17,634.667 1735.5,634C 1726.79,624.292 1718.46,614.292 1710.5,604C 1705.18,603.501 1699.84,603.334 1694.5,603.5C 1694.5,613.833 1694.5,624.167 1694.5,634.5C 1691.5,634.5 1688.5,634.5 1685.5,634.5C 1685.5,611.5 1685.5,588.5 1685.5,565.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#404d56"
        d="M 1759.5,566.5 C 1762.69,565.194 1766.02,565.194 1769.5,566.5C 1778,585.5 1786.5,604.5 1795,623.5C 1803.92,604.65 1812.59,585.65 1821,566.5C 1824.07,565.383 1827.24,565.216 1830.5,566C 1817.86,594.234 1805.03,622.4 1792,650.5C 1785.39,659.375 1776.56,662.542 1765.5,660C 1761.84,659.155 1758.84,657.322 1756.5,654.5C 1757.3,651.867 1758.8,649.867 1761,648.5C 1767.52,653.135 1774.35,653.635 1781.5,650C 1785.13,645.243 1788.13,640.076 1790.5,634.5C 1780.53,611.563 1770.2,588.896 1759.5,566.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fcfcfd"
        d="M 288.5,573.5 C 305.184,571.585 316.017,578.585 321,594.5C 321.193,621.305 308.36,631.139 282.5,624C 268.916,613.227 266.082,600.06 274,584.5C 277.857,579.468 282.69,575.801 288.5,573.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fdfdfd"
        d="M 385.5,573.5 C 401.282,570.818 412.115,576.818 418,591.5C 421.297,617.202 410.13,628.702 384.5,626C 370.934,619.369 365.434,608.536 368,593.5C 370.497,583.841 376.33,577.174 385.5,573.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fdfdfd"
        d="M 1510.5,573.5 C 1536.3,572.803 1546.8,585.137 1542,610.5C 1535.5,624.329 1524.67,629.495 1509.5,626C 1495.63,619.09 1490.13,607.924 1493,592.5C 1495.93,583.408 1501.76,577.075 1510.5,573.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fdfdfd"
        d="M 1608.5,573.5 C 1628.2,571.054 1639.2,579.554 1641.5,599C 1639.95,620.214 1628.61,629.214 1607.5,626C 1592.39,618.257 1587.22,606.09 1592,589.5C 1595.44,581.893 1600.94,576.56 1608.5,573.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fcfdfd"
        d="M 488.5,575.5 C 510.487,574.986 520.654,585.652 519,607.5C 514.463,623.523 503.963,630.023 487.5,627C 472.875,620.587 467.041,609.421 470,593.5C 473.441,584.557 479.608,578.557 488.5,575.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#868e94"
        d="M 92.5,592.5 C 80.1667,592.5 67.8333,592.5 55.5,592.5C 55.5,603.833 55.5,615.167 55.5,626.5C 54.5051,615.012 54.1717,603.345 54.5,591.5C 67.3443,591.171 80.011,591.505 92.5,592.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#fefefe"
        d="M 92.5,592.5 C 106.329,594.152 111.829,601.819 109,615.5C 106.668,621.164 102.501,624.664 96.5,626C 82.8374,626.5 69.1707,626.667 55.5,626.5C 55.5,615.167 55.5,603.833 55.5,592.5C 67.8333,592.5 80.1667,592.5 92.5,592.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#b6bbbe"
        d="M 616.5,565.5 C 616.5,575.833 616.5,586.167 616.5,596.5C 601.657,596.829 586.99,596.496 572.5,595.5C 586.833,595.5 601.167,595.5 615.5,595.5C 615.173,585.319 615.506,575.319 616.5,565.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#abb0b4"
        d="M 1377.5,581.5 C 1380.62,580.521 1383.96,580.187 1387.5,580.5C 1387.5,587.5 1387.5,594.5 1387.5,601.5C 1393.5,601.5 1399.5,601.5 1405.5,601.5C 1405.81,604.713 1405.48,607.713 1404.5,610.5C 1404.5,607.833 1404.5,605.167 1404.5,602.5C 1398.5,602.5 1392.5,602.5 1386.5,602.5C 1386.5,595.5 1386.5,588.5 1386.5,581.5C 1383.5,581.5 1380.5,581.5 1377.5,581.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#798288"
        d="M 572.5,604.5 C 586.99,603.504 601.657,603.171 616.5,603.5C 616.5,613.833 616.5,624.167 616.5,634.5C 615.506,624.681 615.173,614.681 615.5,604.5C 601.167,604.5 586.833,604.5 572.5,604.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#92999e"
        d="M 795.5,604.5 C 790.5,604.5 785.5,604.5 780.5,604.5C 780.827,614.681 780.494,624.681 779.5,634.5C 779.5,624.167 779.5,613.833 779.5,603.5C 785.026,603.179 790.36,603.512 795.5,604.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#b8bcc0"
        d="M 270.5,623.5 C 270.5,635.833 270.5,648.167 270.5,660.5C 266.958,660.813 263.625,660.479 260.5,659.5C 263.5,659.5 266.5,659.5 269.5,659.5C 269.168,646.752 269.501,634.752 270.5,623.5 Z"
      />
    </g>
    <g>
      <path
        style={{
          opacity: 1,
        }}
        fill="#cdd0d3"
        d="M 1050.5,624.5 C 1066.83,625.167 1083.17,625.833 1099.5,626.5C 1082.82,626.833 1066.15,626.499 1049.5,625.5C 1049.62,624.893 1049.96,624.56 1050.5,624.5 Z"
      />
    </g>
  </svg>
);
export default SVGLogo;
