import {Table} from 'react-bootstrap'
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import {useNavigate} from "react-router-dom";
import {emptyOrValue} from "../../../Utils/utils";

const TableRow = ({rowData}) => {
    return (
        <tr>
            <td className="text-truncate">{rowData.delivery_date}</td>
            <td className="text-truncate">{emptyOrValue(rowData.quantity, 0)}</td>
        </tr>
    )
}

const ShipmentTable = ({data}) => {
    return (
        <Table responsive>
            <thead>
            <tr>
                <th className="mnw-150p">Дата</th>
                <th className="mnw-200p">Замовлень</th>
            </tr>
            </thead>
            <tbody>
            {
                data.map(el => <TableRow key={el.row_id} rowData={el}/>)
            }
            </tbody>
        </Table>
    )
}


const ShipmentByDays = () => {

    let navigate = useNavigate()

    const data = useSelector(state => state.Dashboard.shipment_by_days)

    const itemsPerPage = 5;

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;

        if (data) {
            setCurrentItems(data.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(data.length / itemsPerPage));
        }
    }, [itemOffset, itemsPerPage, data]);

    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % data.length;
        setItemOffset(newOffset);
    };


    return (
        <div className="card card-border mb-0 h-100">
            <div className="card-header card-header-action">
                <h6>Відвантаження по датам
                    <span className="badge badge-sm badge-light ms-2">{data.length}</span>
                </h6>
                <div className="card-action-wrap">
                    <button className="btn btn-sm btn-outline-light" onClick={() =>{navigate('/orders/shipments')}}>
                        <span>
                            <span className="icon">
                                <span className="feather-icon"></span>
                            </span>
                            <span className="btn-text">Детально...</span>
                        </span>
                    </button>
                </div>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-sm-12 table-responsive">
                        <ShipmentTable data={currentItems}/>
                    </div>
                </div>
                {/*Pagination*/}
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                            <ReactPaginate
                                className={"pagination custom-pagination pagination-simple pagination-sm"}
                                nextLabel=" >>"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={pageCount}
                                previousLabel="<< "
                                pageClassName="me-1 ms-1 paginate_button page-item"
                                pageLinkClassName="page-link fs--1 shadow pe-3 ps-3 rounded"
                                previousClassName="page-item me-1"
                                previousLinkClassName="page-link fs--1 shadow pe-3 ps-3 rounded"
                                nextClassName="page-item ms-1"
                                nextLinkClassName="page-link fs--1 shadow pe-3 ps-3 rounded"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link fs--1 shadow pe-3 ps-3 rounded"
                                containerClassName="pagination mb-0"
                                activeClassName="active "
                                renderOnZeroPageCount={null}
                            />

                        </div>
                    </div>
                </div>
                {/*Pagination*/}
            </div>
        </div>
    )
}

export default ShipmentByDays