import OrdersTable from "./OrdersTable"
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {setData, setLoading, reset as resetOrdersState} from "../../Reducers/Orders"
import ApiAdapter from "../../Adapters/ApiAdapter";
import DataRepository from "../../Repositories/DataRepository";
import BorderedSpinner from "../Spinner/Spinner";
import {reset as resetPagination} from "../../Reducers/Pagination";
import {filterArrayOfObjects} from "../../Utils/arrayProcessing";
import {setFilteredData, reset as resetFilteredData} from "../../Reducers/FilteredData";
import Pagination from "../Pagination/Pagination";

const InWorkTable = () => {

    const data = useSelector(state => state.Pagination.currentItems)

    return (
        <div className="col-sm-12 table-responsive">
            <OrdersTable data={data}/>
        </div>
    )
}

const InWorkOrders = () => {

    const dispatch = useDispatch()

    const fData = useSelector(state => state.FilteredData)
    const orData = useSelector(state => state.Orders.data)
    const loading = useSelector(state => state.Orders.loading)

    function searchHandler(e) {
        const filteredData = filterArrayOfObjects(orData, e.target.value)
        dispatch(setFilteredData({data: filteredData, searchValue: e.target.value}))
    }

    return (
        <div className="card card-border mb-0 h-100">
            <div className="card-header card-header-action">
                <h6>Замовлення
                    <span className="badge badge-sm badge-light ms-1">{loading ? 0 : orData.length}</span>
                </h6>
                <div className="card-action-wrap">
                    {/*  Free  */}
                </div>
            </div>
            <div className="card-body">
                <div className="contact-list-view">

                    <div id="datable_1_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                        {/*Head*/}
                        <div className="row">
                            <div className="col-10 mb-3">
                                <div className="contact-toolbar-left">

                                    {/*<div className="input-group w-230p mx-3">*/}
                                    {/*    <span className="input-affix-wrapper">*/}
                                    {/*        <span className="input-prefix">*/}
                                    {/*            <span className="feather-icon">*/}
                                    {/*                <FontAwesomeIcon icon={faCalendar} className="feather me-2"/>*/}
                                    {/*            </span>*/}
                                    {/*        </span>*/}
                                    {/*    </span>*/}
                                    {/*</div>*/}

                                </div>
                            </div>
                            <div className="col-2 mb-3">
                                <div className="contact-toolbar-right">
                                    <div id="datable_1_filter" className="dataTables_filter">
                                        <label>
                                            <input placeholder="Пошук" type="search"
                                                   className="form-control form-control-sm"
                                                   defaultValue=""
                                                   onInput={(e) => {
                                                       searchHandler(e)
                                                   }}/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Head*/}
                        {/*Table*/}
                        <div className="row">
                            {(() => {
                                    if (loading === false) {
                                        return <InWorkTable/>
                                    } else
                                        return <div className="my-3 d-flex justify-content-center">
                                            <BorderedSpinner/>
                                        </div>
                                }
                            )()}
                        </div>
                        {/*Table*/}
                        {/*Pagination*/}
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                                    <Pagination itemsPerPage={10} data={(fData.is_filtered) ? fData.data : orData} loading={loading}/>
                                </div>
                            </div>
                        </div>
                        {/*Pagination*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

const getData = async (dispatch) => {

    const apiAdapter = ApiAdapter();
    const {getOrdersInWork} = DataRepository(apiAdapter);
    await getOrdersInWork(dispatch, setData)
    dispatch(setLoading(false))
}

const InWork = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(resetFilteredData())
        dispatch(resetPagination())
        dispatch(resetOrdersState())
        getData(dispatch).then();
    }, []);

    return (
        <div className="container-xxl">
            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">За статусом</h1>
            </div>
            <div className="hk-pg-body">
                <div className="row">
                    <div className="col-md-12 mb-md-4 mb-3">
                        <InWorkOrders/>
                        {/*<ErrorNotification position={'bottom-left'}/>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InWork