import {
    faAddressCard,
    faPhone,
    faAt
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const InfoCard = ({title, name, phone, email}) => {
    return (
        <div className="card card-border mb-0 h-100">
            <div className="card-header card-header-action">
                <FontAwesomeIcon icon={faAddressCard} className="feather fs-5 me-2 text-primary-vst"/>

                <div className="card-action-wrap">
                    <span>{title}</span>
                </div>
            </div>

            <div className="card-body">
                <div className="row mb-2">
                    <div className="col-sm-12">
                        <h5>{name}</h5>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-12">
                        <h6><FontAwesomeIcon icon={faPhone} className="feather fs-5 me-2 text-primary"/>
                            {phone}</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <h6><FontAwesomeIcon icon={faAt} className="feather fs-5 me-2 text-yellow"/>
                            {email}</h6>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InfoCard